export const SliderOurCategory = [
  {
    image: "../assets/images/services/design.jpg",
    title_ru: "Департамент проектирования объектов",
    title_uz: "Объэктни лойиҳалаш бўлими",
    title_oz: "Ob'ektni loyihalash bo'limi",
    title_en: "Object Design Department",
    text_ru: "подробнее...",
    text_uz: "батафсил...",
    text_oz: "batafsil...",
    text_en: "more...",
  },
  {
    image: "../assets/images/services/geology.jpg",
    title_ru: "Департамент геологии и разработки месторождений",
    title_uz: "Геология ва конларни ўзлаштириш бўлими",
    title_oz: "Geologiya va konlarni o'zlashtirish bo'limi",
    title_en: "Department of Geology and Field Development",
    text_ru: "подробнее...",
    text_uz: "батафсил...",
    text_oz: "batafsil...",
    text_en: "more...",
  },
  {
    image: "../assets/images/services/oil.jpg",
    title_ru: "Департамент подготовки и углублённой переработки нефти и газа",
    title_uz: "Нефт ва газни тайёрлаш ва чуқур қайта ишлаш бошқармаси",
    title_oz: "Neft va gazni tayyorlash va chuqur qayta ishlash boshqarmasi",
    title_en: "Department of preparation and deep processing of oil and gas",
    text_ru: "подробнее...",
    text_uz: "батафсил...",
    text_oz: "batafsil...",
    text_en: "more...",
  },
  {
    image: "../assets/images/services/economic.jpeg",
    title_ru: "Департамент экономики и финансов",
    title_uz: "Иқтисодиёт ва молия кафедраси",
    title_oz: "Iqtisodiyot va moliya bo'limi",
    title_en: "Department of Economics and Finance",
    text_ru: "подробнее...",
    text_uz: "батафсил...",
    text_oz: "batafsil...",
    text_en: "more...",
  },
  {
    image: "../assets/images/services/general.jpg",
    title_ru: "Подразделения по общим вопросам",
    title_uz: "Умумий ишлар бўлимлари",
    title_oz: "Umumiy ishlar bo'limlari",
    title_en: "General Affairs Units",
    text_ru: "подробнее...",
    text_uz: "батафсил...",
    text_oz: "batafsil...",
    text_en: "more...",
  },
  {
    image: "../assets/images/services/administration.jpg",
    title_ru: "Аппарат управления и вспомогательные подразделения",
    title_uz: "Маъмурият ва қўллаб-қувватлаш бўлинмалари",
    title_oz: "Ma'muriyat va qo'llab-quvvatlash bo'linmalari",
    title_en: "Administration and support units",
    text_ru: "подробнее...",
    text_uz: "батафсил...",
    text_oz: "batafsil...",
    text_en: "more...",
  },
  {
    image: "../assets/images/services/test.jpg",
    title_ru: "Испытательная база АО «O‘ZLITINEFTGAZ»",
    title_uz: "АО «ЎЗЛИТИНEФТГАЗ» синов базаси",
    title_oz: "АО «O‘ZLITINEFTGAZ» sinov bazasi",
    title_en: 'Test base AO "O\'ZLITINEFTGAZ"',
    text_ru: "подробнее...",
    text_uz: "батафсил...",
    text_oz: "batafsil...",
    text_en: "more...",
  },
];

export const ourMentors = [
  {
    image: "../assets/images/homepage-1/pens-1.jpg",
    fullName_ru: "Шафиев Рустам Умарович",
    fullName_uz: "Шафиев Рустам Умарович",
    fullName_oz: "Shafiyev Rustam Umarovich",
    fullName_en: "Shafiev Rustam Umarovich",
    position_ru: "Консультант-секретарь НТС",
    position_uz: "НТС маслаҳатчиси-котиби",
    position_oz: "NTS maslahatchisi-kotibi",
    position_en: "Consultant-Secretary of the NTS",
  },
  {
    image: "../assets/images/homepage-1/pens-2.jpg",
    fullName_ru: "Хуртинова Наталия Анатольевна",
    fullName_uz: "Хуртинова Наталия Анатольевна",
    fullName_oz: "Xurtinova Natalya Anatolievna",
    fullName_en: "Khurtinova Natalia Anatolievna",
    position_ru: "Начальник Отдела управления контрактами",
    position_uz: "Шартномаларни бошқариш бўлими бошлиғи",
    position_oz: "Shartnomalarni boshqarish bo'limi boshlig'i",
    position_en: "Head of Contract Management Department",
  },
  {
    image: "../assets/images/homepage-1/pens-3.jpg",
    fullName_ru: "Ли Роберт  Чанирович",
    fullName_uz: "Ли Роберт  Чанирович",
    fullName_oz: "Li Robert Chanirovich",
    fullName_en: "Lee Robert Chanirovich",
    position_ru:
      "Главный научный сотрудник Отдела сбора, подготовки и транспорта газа",
    position_uz: "Газни йиғиш, қайта ишлаш ва ташиш бўлими бош илмий ходими",
    position_oz:
      "Gazni yig‘ish, qayta ishlash va tashish bo‘limi bosh ilmiy xodimi",
    position_en:
      "Chief Researcher of the Department of Gathering, Treatment and Transportation of Gas",
  },
  {
    image: "../assets/images/homepage-1/pens-4.jpg",
    fullName_ru: "Досумова Эльза Янгибаевна",
    fullName_uz: "Досумова Эльза Янгибаевна",
    fullName_oz: "Dosumova Elza Yangibaevna",
    fullName_en: "Dosumova Elza Yangibaevna",
    position_ru:
      "Старший научный сотрудник Отдела переработки газа и газохимии",
    position_uz: "Газни қайта ишлаш ва газ кимёси кафедраси катта илмий ходими",
    position_oz:
      "Gazni qayta ishlash va gaz kimyosi kafedrasi katta ilmiy xodimi",
    position_en:
      "Senior Researcher, Department of Gas Processing and Gas Chemistry",
  },
  // {
  //   image: "../assets/images/homepage-1/pens-5.jpg",
  //   fullName_ru: "Атальянц Владимир Аршакович",
  //   fullName_uz: "Атальянц Владимир Аршакович",
  //   fullName_oz: "Atal'yants Vladimir Arshakovich",
  //   fullName_en: "Atal'yants Vladimir Arshakovich",
  //   position_ru: "Главный консультант АО «O‘ZLITINEFTGAZ»",
  //   position_uz: "“ЎЗЛИТИНEФТГАЗ” АЖ бош маслаҳатчиси",
  //   position_oz: "“O‘ZLITINEFTGAZ” AJ bosh maslahatchisi",
  //   position_en: "Chief Consultant of O‘ZLITINEFTGAZ JSC",
  // },
  {
    image: "../assets/images/homepage-1/pens-6.jpg",
    fullName_ru: "Шин Алексей",
    fullName_uz: "Шин Алексей",
    fullName_oz: "Shin Aleksey",
    fullName_en: "Shin Alexey",
    position_ru:
      "Старший научный сотрудник Отдела газодинамических и газоконденсатных исследований и интенсификации добычи нефти и газа",
    position_uz:
      "Газ динамикаси ва газ конденсатини тадқиқ этиш ва нефт ва газ қазиб олишни интенсивлаштириш бўлими катта илмий ходими",
    position_oz:
      "Gaz dinamikasi va gaz kondensatini tadqiq etish va neft va gaz qazib olishni intensivlashtirish bo‘limi katta ilmiy xodimi",
    position_en:
      "Senior Researcher, Department of Gas Dynamic and Gas Condensate Research and Oil and Gas Production Intensification",
  },
  {
    image: "../assets/images/homepage-1/pens-7.jpg",
    fullName_ru: "Салиджанова Нафиса Сагдуллаевна",
    fullName_uz: "Салиджанова Нафиса Сагдуллаевна",
    fullName_oz: "Salidjanova Nafisa Sagdullaevna",
    fullName_en: "Salidzhanova Nafisa Sagdullaevna",
    position_ru: "Главный научный сотрудник Группа антикорозийной защиты",
    position_uz: "Коррозияга қарши ҳимоя гуруҳининг бош тадқиқотчиси",
    position_oz: "Korroziyaga qarshi himoya guruhining bosh tadqiqotchisi",
    position_en: "Chief Researcher Anti-Corrosion Protection Group",
  },
  {
    image: "../assets/images/homepage-1/pens-8.jpg",
    fullName_ru: "Адилов Джавдат",
    fullName_uz: "Адилов Джавдат",
    fullName_oz: "Adilov Javdat",
    fullName_en: "Adilov Javdat",
    position_ru:
      "Заведующий Группой разработки месторождений Устюртского ГДУ Отдела проектирования и мониторинга разработки месторождений углеводородов",
    position_uz:
      "Устюрт ГДУнинг Углеводород конларини лойиҳалаш ва ўзлаштириш мониторинги бошқармасининг конларни ўзлаштириш гуруҳи раҳбари",
    position_oz:
      "Ustyurt GDUning Uglevodorod konlarini loyihalash va o‘zlashtirish monitoringi boshqarmasining konlarni o‘zlashtirish guruhi rahbari",
    position_en:
      "Head of the Field Development Group of the Ustyurt GDU of the Department for Design and Monitoring of the Development of Hydrocarbon Fields",
  },
  {
    image: "../assets/images/homepage-1/pens-9.jpg",
    fullName_ru: "Бободжанова Рано Васидовна",
    fullName_uz: "Бободжанова Рано Васидовна",
    fullName_oz: "Bobodjanova Rano Vasidovna",
    fullName_en: "Bobodzhanova Rano Vasidovna",
    position_ru:
      "Заведующий  лабораторией физико-химических исследований углеводородных смесей",
    position_uz:
      "Углеводород аралашмаларини физик-кимёвий тадқиқотлар лабораторияси мудири",
    position_oz:
      "Uglevodorod aralashmalarini fizik-kimyoviy tadqiqotlar laboratoriyasi mudiri",
    position_en:
      "Head of the Laboratory for Physical and Chemical Research of Hydrocarbon Mixtures",
  },
  {
    image: "../assets/images/homepage-1/pens-10.jpg",
    fullName_ru: "Шевцов Владимир Михайлович",
    fullName_uz: "Шевцов Владимир Михайлович",
    fullName_oz: "Shevtsov Vladimir Mixaylovich",
    fullName_en: "Shevtsov Vladimir Mikhailovich",
    position_ru:
      "Ведущий научный сотрудник Отдела проектирования и мониторинга разработки месторождений углеводородов",
    position_uz:
      "Углеводород конларини лойиҳалаш ва ўзлаштириш мониторинги бўлими етакчи илмий ходими",
    position_oz:
      "Uglevodorod konlarini loyihalash va o‘zlashtirish monitoringi bo‘limi yetakchi ilmiy xodimi",
    position_en:
      "Leading Researcher of the Department for Design and Monitoring of Development of Hydrocarbon Fields",
  },
  {
    image: "../assets/images/homepage-1/pens-11.jpg",
    fullName_ru: "Муминов Анвар Султанович",
    fullName_uz: "Муминов Анвар Султанович",
    fullName_oz: "Muminov Anvar Sultanovich",
    fullName_en: "Muminov Anvar Sultanovich",
    position_ru: "Заведующий лабораторией петрофизических исследований",
    position_uz: "Петрофизика тадқиқотлари лабораторияси мудири",
    position_oz: "Petrofizika tadqiqotlari laboratoriyasi mudiri",
    position_en: "Head of the laboratory of petrophysical research",
  },
  // {
  //   image: "../assets/images/homepage-1/pens-12.jpg",
  //   fullName_ru: "Прокопенко Людмила Алексеевна",
  //   fullName_uz: "Прокопенко Людмила Алексеевна",
  //   fullName_oz: "Prokopenko Lyudmila Alekseevna",
  //   fullName_en: "Prokopenko Ludmila Alekseevna",
  //   position_ru: "Заведующий  группой Отдела управления контрактами",
  //   position_uz: "Жамоа раҳбари, шартномаларни бошқариш бўлими",
  //   position_oz: "Jamoa rahbari, shartnomalarni boshqarish bo'limi",
  //   position_en: "Team Leader, Contract Management Department",
  // },
];

export const cheifEngineers = [
  // {
  //   image: "../assets/images/services/gipi-1.jpg",
  //   fullName_ru: "Гаифов Жуманазар Хужаназарович",
  //   fullName_uz: "Гаифов Жуманазар Хужаназарович",
  //   fullName_oz: "Gaifov Jumanazar Khujanazarovich",
  //   fullName_en: "Gaifov Zhumanazar Khujanazarovich",
  //   position_ru: "Заместитель ГИПа",
  //   position_uz: "Заместитель ГИПа",
  //   position_oz: "Заместитель ГИПа",
  //   position_en: "Заместитель ГИПа",
  //   text_ru:
  //     "<p>\
  //                       <strong>Гаифов Жуманазар Хожаназарович</strong> вел свою деятельность в качестве Главного инженера проекта АО «O‘ZLITINEFTGAZ» с октября 2019 года до 2022 гг. С января 2022 года ведет свою деятельность в качестве Зам. Главного инженера проекта.\
  //                   </p>\
  //                   <p>За период работы под его руководством успешно разработаны следующие проекты:</p>\
  //                   <ul>\
  //                       <li>РП «Дообустройство месторождения Гарбы»;</li>\
  //                       <li>РП «Строительство установки грануляции серы №2 (цеха №5) на «Мубарекский ГПЗ»;</li>\
  //                       <li>РП «Обустройство месторождения Тумарис»;</li>\
  //                       <li>РП «Строительство УППГ на месторождении Тумарис»;</li>\
  //                       <li>РП «Обустройство месторождения Андакли»;</li>\
  //                       <li>РП «Обустройство месторождения Шорток»;</li>\
  //                       <li>РП «Обустройство месторождения Жанубий Кулбешкак»;</li>\
  //                       <li>РП «Дообустройство месторождения Кулбешкак»;</li>\
  //                       <li>РП «Строительство навеса для хранения комовой серы на Мубарекский ГПЗ АО «Узбекнефтегаз»;</li>\
  //                       <li>РП «Реконструкция факельного хозяйства на Мубарекский ГПЗ АО «Узбекнефтегаз»;</li>\
  //                       <li>УТЭР «Реконструкция системы охлаждения воды, канализация и очистного сооружения на Мубарекский ГПЗ АО «Узбекнефтегаз»;</li>\
  //                       <li>УТЭР «Реконструкция установка получения серы №3 (цеха №5) на Мубарекский ГПЗ АО «Узбекнефтегаз»;</li>\
  //                       <li>РП «Перенос газопровода товарного газа от ДКС до УЦОГ цеха № 18 Мубарекский ГПЗ»;</li>\
  //                       <li>РП «Реконструкция системы охлаждения газа цеха №4 Мубарекского ГПЗ»;</li>\
  //                       <li>РП «Строительство переходов на местах пересечении действующих коммуникации Газлийского НГДУ с Амударё Ўнгқирғоғи коллектори»;</li>\
  //                       <li>Строительство запасного железобетонного водоема V-500 м3 (2-ед) на Существующей территории «Насосной» для стабильного обеспечения Технической водой СОУ «Учкыр”;</li>\
  //                       <li>РП «Строительство газопровода от БВН м/р Саватли до газопровода Д-426х9мм Даяхатын-СОУ Учкыр для отдельной эксплуатации месторождения Тумарис»;</li>\
  //                       <li>РП «Расширение газотранспортной сис-мы от БВН-2 м-я Тайлак до газового коллектора Д-720х16мм «Тайлок-УППГ Кумли» Газлийского НГДУ;</li>\
  //                       <li>РП «Стр-во запасного железобетонного водоема V=500м3 (2ед) на существующей территории «Насосной» для стабильного обеспечения тех-кой водой СОУ «Учкыр»</li>\
  //                       <li>РП «Расширение газотранспортной сис-мы от БВН-2 м-я Тайлак до газового коллектора Д-720х16мм «Тайлок-УППГ Кумли» Газлийского НГДУ».</li>\
  //                       <li><p> Под руководством ГИПа принимал участие в разработке проектов:</p><ul><li>РП «Дообус-во м-я Кульбешкак скв. № 67»;</li><li>РП «Дообус-во м-я Хажиказган скв. № 66».</li></ul></li>\
  //                   </ul></br>\
  //                   <p><strong>Текущие проекты (2022г.):</strong></p>\
  //                   <ul>\
  //                       <li>РП «Обустройство месторождения Шаркий Хатар Газлийского НГДУ»;</li>\
  //                       <li>РП «Дообустройство месторождения Кульбешкак скважины № 65, 66»;</li>\
  //                       <li>РП «Обустройство месторождения Северный Тегермен»;</li>\
  //                       <li>РП «Строительство газопровода от месторождения Янгиказган до УППГ Янгиказган Газлийского НГДУ»;</li>\
  //                       <li>«Расширение газотранспортной системы от БВН-2 месторождения Тайлок до газового коллектора Ǿ720х16 мм «Тайлок-УППГ Кумли» Газлийского НГДУ»;</li>\
  //                       <li>«Обустройство месторождения Гарбий Тегирмен скважины №№ 10, 11,12, 13, 14, 15»;</li>\
  //                       <li>«Обустройство месторождения Тегирмен скважины №№ 13, 14, 15, 16, 17»;</li>\
  //                       <li>«Монтажу паропровода высокого давления, трубопровода пароконденсата низкого давления и редукционно-охладительной  установки, железобетонной эстакады, от ООО «Uzbekistаn GTL» до ООО «Шуртанский ГХК».</li>\
  //                       <li>«Дообустройство месторождения Даяхатын скважина №106» и другие.</li>\
  //                   </ul>",
  //   text_uz:
  //     "<p>\
  //                   <strong>Гаифов Жуманазар Хожаназарович</strong> вел свою деятельность в качестве Главного инженера проекта АО «O‘ZLITINEFTGAZ» с октября 2019 года до 2022 гг. С января 2022 года ведет свою деятельность в качестве Зам. Главного инженера проекта.\
  //                   </p>\
  //                   <p>За период работы под его руководством успешно разработаны следующие проекты:</p>\
  //                   <ul>\
  //                       <li>РП «Дообустройство месторождения Гарбы»;</li>\
  //                       <li>РП «Строительство установки грануляции серы №2 (цеха №5) на «Мубарекский ГПЗ»;</li>\
  //                       <li>РП «Обустройство месторождения Тумарис»;</li>\
  //                       <li>РП «Строительство УППГ на месторождении Тумарис»;</li>\
  //                       <li>РП «Обустройство месторождения Андакли»;</li>\
  //                       <li>РП «Обустройство месторождения Шорток»;</li>\
  //                       <li>РП «Обустройство месторождения Жанубий Кулбешкак»;</li>\
  //                       <li>РП «Дообустройство месторождения Кулбешкак»;</li>\
  //                       <li>РП «Строительство навеса для хранения комовой серы на Мубарекский ГПЗ АО «Узбекнефтегаз»;</li>\
  //                       <li>РП «Реконструкция факельного хозяйства на Мубарекский ГПЗ АО «Узбекнефтегаз»;</li>\
  //                       <li>УТЭР «Реконструкция системы охлаждения воды, канализация и очистного сооружения на Мубарекский ГПЗ АО «Узбекнефтегаз»;</li>\
  //                       <li>УТЭР «Реконструкция установка получения серы №3 (цеха №5) на Мубарекский ГПЗ АО «Узбекнефтегаз»;</li>\
  //                       <li>РП «Перенос газопровода товарного газа от ДКС до УЦОГ цеха № 18 Мубарекский ГПЗ»;</li>\
  //                       <li>РП «Реконструкция системы охлаждения газа цеха №4 Мубарекского ГПЗ»;</li>\
  //                       <li>РП «Строительство переходов на местах пересечении действующих коммуникации Газлийского НГДУ с Амударё Ўнгқирғоғи коллектори»;</li>\
  //                       <li>Строительство запасного железобетонного водоема V-500 м3 (2-ед) на Существующей территории «Насосной» для стабильного обеспечения Технической водой СОУ «Учкыр”;</li>\
  //                       <li>РП «Строительство газопровода от БВН м/р Саватли до газопровода Д-426х9мм Даяхатын-СОУ Учкыр для отдельной эксплуатации месторождения Тумарис»;</li>\
  //                       <li>РП «Расширение газотранспортной сис-мы от БВН-2 м-я Тайлак до газового коллектора Д-720х16мм «Тайлок-УППГ Кумли» Газлийского НГДУ;</li>\
  //                       <li>РП «Стр-во запасного железобетонного водоема V=500м3 (2ед) на существующей территории «Насосной» для стабильного обеспечения тех-кой водой СОУ «Учкыр»</li>\
  //                       <li>РП «Расширение газотранспортной сис-мы от БВН-2 м-я Тайлак до газового коллектора Д-720х16мм «Тайлок-УППГ Кумли» Газлийского НГДУ».</li>\
  //                       <li><p> Под руководством ГИПа принимал участие в разработке проектов:</p><ul><li>РП «Дообус-во м-я Кульбешкак скв. № 67»;</li><li>РП «Дообус-во м-я Хажиказган скв. № 66».</li></ul></li>\
  //                   </ul></br>\
  //                   <p><strong>Текущие проекты (2022г.):</strong></p>\
  //                   <ul>\
  //                       <li>РП «Обустройство месторождения Шаркий Хатар Газлийского НГДУ»;</li>\
  //                       <li>РП «Дообустройство месторождения Кульбешкак скважины № 65, 66»;</li>\
  //                       <li>РП «Обустройство месторождения Северный Тегермен»;</li>\
  //                       <li>РП «Строительство газопровода от месторождения Янгиказган до УППГ Янгиказган Газлийского НГДУ»;</li>\
  //                       <li>«Расширение газотранспортной системы от БВН-2 месторождения Тайлок до газового коллектора Ǿ720х16 мм «Тайлок-УППГ Кумли» Газлийского НГДУ»;</li>\
  //                       <li>«Обустройство месторождения Гарбий Тегирмен скважины №№ 10, 11,12, 13, 14, 15»;</li>\
  //                       <li>«Обустройство месторождения Тегирмен скважины №№ 13, 14, 15, 16, 17»;</li>\
  //                       <li>«Монтажу паропровода высокого давления, трубопровода пароконденсата низкого давления и редукционно-охладительной  установки, железобетонной эстакады, от ООО «Uzbekistаn GTL» до ООО «Шуртанский ГХК».</li>\
  //                       <li>«Дообустройство месторождения Даяхатын скважина №106» и другие.</li>\
  //                   </ul>",
  //   text_oz:
  //     "<p>\
  //                       <strong>Гаифов Жуманазар Хожаназарович</strong> вел свою деятельность в качестве Главного инженера проекта АО «O‘ZLITINEFTGAZ» с октября 2019 года до 2022 гг. С января 2022 года ведет свою деятельность в качестве Зам. Главного инженера проекта.\
  //                   </p>\
  //                   <p>За период работы под его руководством успешно разработаны следующие проекты:</p>\
  //                   <ul>\
  //                       <li>РП «Дообустройство месторождения Гарбы»;</li>\
  //                       <li>РП «Строительство установки грануляции серы №2 (цеха №5) на «Мубарекский ГПЗ»;</li>\
  //                       <li>РП «Обустройство месторождения Тумарис»;</li>\
  //                       <li>РП «Строительство УППГ на месторождении Тумарис»;</li>\
  //                       <li>РП «Обустройство месторождения Андакли»;</li>\
  //                       <li>РП «Обустройство месторождения Шорток»;</li>\
  //                       <li>РП «Обустройство месторождения Жанубий Кулбешкак»;</li>\
  //                       <li>РП «Дообустройство месторождения Кулбешкак»;</li>\
  //                       <li>РП «Строительство навеса для хранения комовой серы на Мубарекский ГПЗ АО «Узбекнефтегаз»;</li>\
  //                       <li>РП «Реконструкция факельного хозяйства на Мубарекский ГПЗ АО «Узбекнефтегаз»;</li>\
  //                       <li>УТЭР «Реконструкция системы охлаждения воды, канализация и очистного сооружения на Мубарекский ГПЗ АО «Узбекнефтегаз»;</li>\
  //                       <li>УТЭР «Реконструкция установка получения серы №3 (цеха №5) на Мубарекский ГПЗ АО «Узбекнефтегаз»;</li>\
  //                       <li>РП «Перенос газопровода товарного газа от ДКС до УЦОГ цеха № 18 Мубарекский ГПЗ»;</li>\
  //                       <li>РП «Реконструкция системы охлаждения газа цеха №4 Мубарекского ГПЗ»;</li>\
  //                       <li>РП «Строительство переходов на местах пересечении действующих коммуникации Газлийского НГДУ с Амударё Ўнгқирғоғи коллектори»;</li>\
  //                       <li>Строительство запасного железобетонного водоема V-500 м3 (2-ед) на Существующей территории «Насосной» для стабильного обеспечения Технической водой СОУ «Учкыр”;</li>\
  //                       <li>РП «Строительство газопровода от БВН м/р Саватли до газопровода Д-426х9мм Даяхатын-СОУ Учкыр для отдельной эксплуатации месторождения Тумарис»;</li>\
  //                       <li>РП «Расширение газотранспортной сис-мы от БВН-2 м-я Тайлак до газового коллектора Д-720х16мм «Тайлок-УППГ Кумли» Газлийского НГДУ;</li>\
  //                       <li>РП «Стр-во запасного железобетонного водоема V=500м3 (2ед) на существующей территории «Насосной» для стабильного обеспечения тех-кой водой СОУ «Учкыр»</li>\
  //                       <li>РП «Расширение газотранспортной сис-мы от БВН-2 м-я Тайлак до газового коллектора Д-720х16мм «Тайлок-УППГ Кумли» Газлийского НГДУ».</li>\
  //                       <li><p> Под руководством ГИПа принимал участие в разработке проектов:</p><ul><li>РП «Дообус-во м-я Кульбешкак скв. № 67»;</li><li>РП «Дообус-во м-я Хажиказган скв. № 66».</li></ul></li>\
  //                   </ul></br>\
  //                   <p><strong>Текущие проекты (2022г.):</strong></p>\
  //                   <ul>\
  //                       <li>РП «Обустройство месторождения Шаркий Хатар Газлийского НГДУ»;</li>\
  //                       <li>РП «Дообустройство месторождения Кульбешкак скважины № 65, 66»;</li>\
  //                       <li>РП «Обустройство месторождения Северный Тегермен»;</li>\
  //                       <li>РП «Строительство газопровода от месторождения Янгиказган до УППГ Янгиказган Газлийского НГДУ»;</li>\
  //                       <li>«Расширение газотранспортной системы от БВН-2 месторождения Тайлок до газового коллектора Ǿ720х16 мм «Тайлок-УППГ Кумли» Газлийского НГДУ»;</li>\
  //                       <li>«Обустройство месторождения Гарбий Тегирмен скважины №№ 10, 11,12, 13, 14, 15»;</li>\
  //                       <li>«Обустройство месторождения Тегирмен скважины №№ 13, 14, 15, 16, 17»;</li>\
  //                       <li>«Монтажу паропровода высокого давления, трубопровода пароконденсата низкого давления и редукционно-охладительной  установки, железобетонной эстакады, от ООО «Uzbekistаn GTL» до ООО «Шуртанский ГХК».</li>\
  //                       <li>«Дообустройство месторождения Даяхатын скважина №106» и другие.</li>\
  //                   </ul>",
  //   text_en:
  //     "<p>\
  //                   <strong>Гаифов Жуманазар Хожаназарович</strong> вел свою деятельность в качестве Главного инженера проекта АО «O‘ZLITINEFTGAZ» с октября 2019 года до 2022 гг. С января 2022 года ведет свою деятельность в качестве Зам. Главного инженера проекта.\
  //               </p>\
  //               <p>За период работы под его руководством успешно разработаны следующие проекты:</p>\
  //               <ul>\
  //                   <li>РП «Дообустройство месторождения Гарбы»;</li>\
  //                   <li>РП «Строительство установки грануляции серы №2 (цеха №5) на «Мубарекский ГПЗ»;</li>\
  //                   <li>РП «Обустройство месторождения Тумарис»;</li>\
  //                   <li>РП «Строительство УППГ на месторождении Тумарис»;</li>\
  //                   <li>РП «Обустройство месторождения Андакли»;</li>\
  //                   <li>РП «Обустройство месторождения Шорток»;</li>\
  //                   <li>РП «Обустройство месторождения Жанубий Кулбешкак»;</li>\
  //                   <li>РП «Дообустройство месторождения Кулбешкак»;</li>\
  //                   <li>РП «Строительство навеса для хранения комовой серы на Мубарекский ГПЗ АО «Узбекнефтегаз»;</li>\
  //                   <li>РП «Реконструкция факельного хозяйства на Мубарекский ГПЗ АО «Узбекнефтегаз»;</li>\
  //                   <li>УТЭР «Реконструкция системы охлаждения воды, канализация и очистного сооружения на Мубарекский ГПЗ АО «Узбекнефтегаз»;</li>\
  //                   <li>УТЭР «Реконструкция установка получения серы №3 (цеха №5) на Мубарекский ГПЗ АО «Узбекнефтегаз»;</li>\
  //                   <li>РП «Перенос газопровода товарного газа от ДКС до УЦОГ цеха № 18 Мубарекский ГПЗ»;</li>\
  //                   <li>РП «Реконструкция системы охлаждения газа цеха №4 Мубарекского ГПЗ»;</li>\
  //                   <li>РП «Строительство переходов на местах пересечении действующих коммуникации Газлийского НГДУ с Амударё Ўнгқирғоғи коллектори»;</li>\
  //                   <li>Строительство запасного железобетонного водоема V-500 м3 (2-ед) на Существующей территории «Насосной» для стабильного обеспечения Технической водой СОУ «Учкыр”;</li>\
  //                   <li>РП «Строительство газопровода от БВН м/р Саватли до газопровода Д-426х9мм Даяхатын-СОУ Учкыр для отдельной эксплуатации месторождения Тумарис»;</li>\
  //                   <li>РП «Расширение газотранспортной сис-мы от БВН-2 м-я Тайлак до газового коллектора Д-720х16мм «Тайлок-УППГ Кумли» Газлийского НГДУ;</li>\
  //                   <li>РП «Стр-во запасного железобетонного водоема V=500м3 (2ед) на существующей территории «Насосной» для стабильного обеспечения тех-кой водой СОУ «Учкыр»</li>\
  //                   <li>РП «Расширение газотранспортной сис-мы от БВН-2 м-я Тайлак до газового коллектора Д-720х16мм «Тайлок-УППГ Кумли» Газлийского НГДУ».</li>\
  //                   <li><p> Под руководством ГИПа принимал участие в разработке проектов:</p><ul><li>РП «Дообус-во м-я Кульбешкак скв. № 67»;</li><li>РП «Дообус-во м-я Хажиказган скв. № 66».</li></ul></li>\
  //               </ul></br>\
  //               <p><strong>Текущие проекты (2022г.):</strong></p>\
  //               <ul>\
  //                   <li>РП «Обустройство месторождения Шаркий Хатар Газлийского НГДУ»;</li>\
  //                   <li>РП «Дообустройство месторождения Кульбешкак скважины № 65, 66»;</li>\
  //                   <li>РП «Обустройство месторождения Северный Тегермен»;</li>\
  //                   <li>РП «Строительство газопровода от месторождения Янгиказган до УППГ Янгиказган Газлийского НГДУ»;</li>\
  //                   <li>«Расширение газотранспортной системы от БВН-2 месторождения Тайлок до газового коллектора Ǿ720х16 мм «Тайлок-УППГ Кумли» Газлийского НГДУ»;</li>\
  //                   <li>«Обустройство месторождения Гарбий Тегирмен скважины №№ 10, 11,12, 13, 14, 15»;</li>\
  //                   <li>«Обустройство месторождения Тегирмен скважины №№ 13, 14, 15, 16, 17»;</li>\
  //                   <li>«Монтажу паропровода высокого давления, трубопровода пароконденсата низкого давления и редукционно-охладительной  установки, железобетонной эстакады, от ООО «Uzbekistаn GTL» до ООО «Шуртанский ГХК».</li>\
  //                   <li>«Дообустройство месторождения Даяхатын скважина №106» и другие.</li>\
  //               </ul>",
  //   id: 1,
  // },
  {
    image: "../assets/images/services/gipi-2.jpg",
    fullName_ru: "Абдурашидов Акбар Абдурасулович",
    fullName_uz: "Абдурашидов Акбар Абдурасулович",
    fullName_oz: "Abdurashidov Akbar Abdurasulovich",
    fullName_en: "Abdurashidov Akbar Abdurasulovich",
    position_ru: "ГИП",
    position_uz: "ГИП",
    position_oz: "ГИП",
    position_en: "ГИП",
    text_ru:
      "<p>\
          <strong>Абдурашидов Акбар Абдурасулович</strong> ведет свою деятельность в качестве Главного инженера проекта АО «O‘ZLITINEFTGAZ» с января 2021 года.\
      </p>\
      <p>За период работы под его руководством успешно разработаны следующие проекты:</p>\
      <ul>\
          <li>РП «Дообустройство месторождения Шумак»;</li>\
          <li>РП «Дообустройство месторождения Южный Мубарек»;</li>\
          <li>РП «Дообустройство месторождения Истмок»;</li>\
          <li>РП «Дообустройство месторождения Узуншор»;</li>\
          <li>РП «Обустройство месторождения Дультатепа»;</li>\
          <li>РП «Обустройство месторождения Ходжи-Хайрам»;</li>\
          <li>РП «Обустройство месторождения Янги Кызыл-Рабат»;</li>\
          <li>УТЭР «Модернизация ГНКС Келлог для выработки электроэнергии»;</li>\
          <li>НИР «Ведомственные правила по охране линейной части магистральных газопроводов «Узбекистан-Китай».</li>\
          <li>РП «Дообустройство месторождения Каракум со строительством газопровода»;</li>\
          <li>РП «Дообустройство месторождений Северный Мубарек, Западный Тегермен, Тегермен, Северный Уртабулак, Умид»;</li>\
          <li>РП «Обустройство скважин месторождения Западный Тегермен»;</li>\
          <li>РП «Дообустройство месторождения Северный Мубарек с расширением системы сбора, подготовки и транспорта газа»;</li>\
          <li>РП «Строительство газопровода от СП-2 Южный Мубарек до МГПЗ»;</li>\
          <li>РП «Дообустройство месторождения Чулкувар со строительством установки предварительной подготовки газа (УППГ) Чулкувар»;</li>\
          <li>РП «Дообустройство месторождений Южный Мубарек, Янги Кизилработ, Южный Памук, Денгизкуль»;</li>\
          <li>РП «Строительство газопровода-коллектора от месторождения Даяхатын до Газлийского ГПЗ»;</li>\
      </ul></br>\
      <p><strong>Текущие проекты (2022г.):</strong></p>\
      <ul>\
          <li>ТЭО проекта «Поддержание добычи природного газа и газового конденсата по месторождениям АО «Узбекнефтегаз» на 2022-2026 годы» (по объектам Мубарекского НГДУ);</li>\
          <li>РП «Обустройство скважин № 324, 325, 326, 327, 328, 329, 330 месторождения Зеварды, № 239 месторождения Уртабулак, № 9 месторождения Западный Тегермен, № 12 месторождения Тегермен»;</li>\
          <li>РП «Обустройство месторождения Северный Тегермен»;</li>\
          <li>РП «Обустройство месторождений Ижобат и Уртабулак (терриген)»;</li>\
          <li>РП «Обустройство месторождения Уртабулак скважины №240, 241»;</li>\
          <li>РП «Модернизация системы сбора и транспорта газа Тегерменской группы месторождений»;</li>\
          <li>УТЭР «Выработка электроэнергии с помощью паровой турбины с использованием избыточной энергии пара в котельной №2 Мубарекского ГПЗ»;</li>\
          <li>УТЭР «Установка детандер-генераторного агрегата в системе редуцирования газа на Мубарекском ГПЗ для выработки электроэнергии»;</li>\
          <li>НИР «Геолого-технический проект разработки участка Ходжасаят газоконденсатного месторождения (ГКМ) Денгизкуль, ГКМ Ходжадавлат и ГКМ Шаркий Алат с учетом наземного обустройства»;</li>\
          <li>АН «Строительство пожарных депо на месторождениях Северные Шады и Кувачи Алат»;</li>\
          <li>РП «Расширение газотранспортной системы Тегерменской группы месторождений»;</li>\
          <li>РП «Строительство установки предварительной подготовки газа на участке Ходжасаят газоконденсатного месторождения Денгизкуль»;</li>\
          <li>РП «Внедрение автоматизированной системы управления технологическим процессом, включая контроль, регулирование, блокировку и противоаварийную защиту на НГКМ «Кокдумалак» и другие предпроектные работы по объектам добычи, сбора, компримирования, транспортировки, подготовки и переработки углеводородов.</li>\
      </ul>",
    text_uz:
      '<p>\
          <strong>Abdurashidov Akbar Abdurasulovich</strong> 2021-йил январ ойидан буён “ЎЗЛИТИНEФТГАЗ” АЖ бош лойиҳа муҳандиси лавозимида ишлаб келмоқда.\
      </p>\
      <p>У раҳбарлик қилган даврда қуйидаги лойиҳалар муваффақиятли ишлаб чиқилди:</p>\
      <ul>\
          <li>РП "Шумак конини реконструкция қилиш";</li>\
          <li>“Жанубий Муборак конини реконструкция қилиш” РП;</li>\
          <li>“Истмоқ конини реконструкция қилиш” РП;</li>\
          <li>“Узуншўр конини реконструкция қилиш” РП;</li>\
          <li>“Дултатепа конини ўзлаштириш” РП;</li>\
          <li>РП «Обустройство месторождения Ходжи-Хайрам»;</li>\
          <li>РП «Обустройство месторождения Янги Кызыл-Рабат»;</li>\
          <li>УТЭР «Модернизация ГНКС Келлог для выработки электроэнергии»;</li>\
          <li>НИР «Ведомственные правила по охране линейной части магистральных газопроводов «Узбекистан-Китай».</li>\
          <li>РП «Дообустройство месторождения Каракум со строительством газопровода»;</li>\
          <li>РП «Дообустройство месторождений Северный Мубарек, Западный Тегермен, Тегермен, Северный Уртабулак, Умид»;</li>\
          <li>РП «Обустройство скважин месторождения Западный Тегермен»;</li>\
          <li>РП «Дообустройство месторождения Северный Мубарек с расширением системы сбора, подготовки и транспорта газа»;</li>\
          <li>РП «Строительство газопровода от СП-2 Южный Мубарек до МГПЗ»;</li>\
          <li>РП «Дообустройство месторождения Чулкувар со строительством установки предварительной подготовки газа (УППГ) Чулкувар»;</li>\
          <li>РП «Дообустройство месторождений Южный Мубарек, Янги Кизилработ, Южный Памук, Денгизкуль»;</li>\
          <li>РП «Строительство газопровода-коллектора от месторождения Даяхатын до Газлийского ГПЗ»;</li>\
      </ul></br>\
      <p><strong>Текущие проекты (2022г.):</strong></p>\
      <ul>\
          <li>ТЭО проекта «Поддержание добычи природного газа и газового конденсата по месторождениям АО «Узбекнефтегаз» на 2022-2026 годы» (по объектам Мубарекского НГДУ);</li>\
          <li>РП «Обустройство скважин № 324, 325, 326, 327, 328, 329, 330 месторождения Зеварды, № 239 месторождения Уртабулак, № 9 месторождения Западный Тегермен, № 12 месторождения Тегермен»;</li>\
          <li>РП «Обустройство месторождения Северный Тегермен»;</li>\
          <li>РП «Обустройство месторождений Ижобат и Уртабулак (терриген)»;</li>\
          <li>РП «Обустройство месторождения Уртабулак скважины №240, 241»;</li>\
          <li>РП «Модернизация системы сбора и транспорта газа Тегерменской группы месторождений»;</li>\
          <li>УТЭР «Выработка электроэнергии с помощью паровой турбины с использованием избыточной энергии пара в котельной №2 Мубарекского ГПЗ»;</li>\
          <li>УТЭР «Установка детандер-генераторного агрегата в системе редуцирования газа на Мубарекском ГПЗ для выработки электроэнергии»;</li>\
          <li>НИР «Геолого-технический проект разработки участка Ходжасаят газоконденсатного месторождения (ГКМ) Денгизкуль, ГКМ Ходжадавлат и ГКМ Шаркий Алат с учетом наземного обустройства»;</li>\
          <li>АН «Строительство пожарных депо на месторождениях Северные Шады и Кувачи Алат»;</li>\
          <li>РП «Расширение газотранспортной системы Тегерменской группы месторождений»;</li>\
          <li>РП «Строительство установки предварительной подготовки газа на участке Ходжасаят газоконденсатного месторождения Денгизкуль»;</li>\
          <li>РП «Внедрение автоматизированной системы управления технологическим процессом, включая контроль, регулирование, блокировку и противоаварийную защиту на НГКМ «Кокдумалак» и другие предпроектные работы по объектам добычи, сбора, компримирования, транспортировки, подготовки и переработки углеводородов.</li>\
      </ul> ',
    text_oz:
      '<p>\
          <strong>Abdurashidov Akbar Abdurasulovich</strong> 2021-yil yanvar oyidan buyon “O‘ZLITINEFTGAZ” AJ bosh loyiha muhandisi lavozimida ishlab kelmoqda.\
      </p>\
      <p>U rahbarlik qilgan davrda quyidagi loyihalar muvaffaqiyatli ishlab chiqildi:</p>\
      <ul>\
          <li>RP "Shumak konini rekonstruksiya qilish";</li>\
          <li>“Janubiy Muborak konini rekonstruksiya qilish” RP;</li>\
          <li>“Istmoq konini rekonstruksiya qilish” RP;</li>\
          <li>“Uzunsho‘r konini rekonstruksiya qilish” RP;</li>\
          <li>“Dultatepa konini o‘zlashtirish” RP;</li>\
          <li>РП «Обустройство месторождения Ходжи-Хайрам»;</li>\
          <li>РП «Обустройство месторождения Янги Кызыл-Рабат»;</li>\
          <li>УТЭР «Модернизация ГНКС Келлог для выработки электроэнергии»;</li>\
          <li>НИР «Ведомственные правила по охране линейной части магистральных газопроводов «Узбекистан-Китай».</li>\
          <li>РП «Дообустройство месторождения Каракум со строительством газопровода»;</li>\
          <li>РП «Дообустройство месторождений Северный Мубарек, Западный Тегермен, Тегермен, Северный Уртабулак, Умид»;</li>\
          <li>РП «Обустройство скважин месторождения Западный Тегермен»;</li>\
          <li>РП «Дообустройство месторождения Северный Мубарек с расширением системы сбора, подготовки и транспорта газа»;</li>\
          <li>РП «Строительство газопровода от СП-2 Южный Мубарек до МГПЗ»;</li>\
          <li>РП «Дообустройство месторождения Чулкувар со строительством установки предварительной подготовки газа (УППГ) Чулкувар»;</li>\
          <li>РП «Дообустройство месторождений Южный Мубарек, Янги Кизилработ, Южный Памук, Денгизкуль»;</li>\
          <li>РП «Строительство газопровода-коллектора от месторождения Даяхатын до Газлийского ГПЗ»;</li>\
      </ul></br>\
      <p><strong>Текущие проекты (2022г.):</strong></p>\
      <ul>\
          <li>ТЭО проекта «Поддержание добычи природного газа и газового конденсата по месторождениям АО «Узбекнефтегаз» на 2022-2026 годы» (по объектам Мубарекского НГДУ);</li>\
          <li>РП «Обустройство скважин № 324, 325, 326, 327, 328, 329, 330 месторождения Зеварды, № 239 месторождения Уртабулак, № 9 месторождения Западный Тегермен, № 12 месторождения Тегермен»;</li>\
          <li>РП «Обустройство месторождения Северный Тегермен»;</li>\
          <li>РП «Обустройство месторождений Ижобат и Уртабулак (терриген)»;</li>\
          <li>РП «Обустройство месторождения Уртабулак скважины №240, 241»;</li>\
          <li>РП «Модернизация системы сбора и транспорта газа Тегерменской группы месторождений»;</li>\
          <li>УТЭР «Выработка электроэнергии с помощью паровой турбины с использованием избыточной энергии пара в котельной №2 Мубарекского ГПЗ»;</li>\
          <li>УТЭР «Установка детандер-генераторного агрегата в системе редуцирования газа на Мубарекском ГПЗ для выработки электроэнергии»;</li>\
          <li>НИР «Геолого-технический проект разработки участка Ходжасаят газоконденсатного месторождения (ГКМ) Денгизкуль, ГКМ Ходжадавлат и ГКМ Шаркий Алат с учетом наземного обустройства»;</li>\
          <li>АН «Строительство пожарных депо на месторождениях Северные Шады и Кувачи Алат»;</li>\
          <li>РП «Расширение газотранспортной системы Тегерменской группы месторождений»;</li>\
          <li>РП «Строительство установки предварительной подготовки газа на участке Ходжасаят газоконденсатного месторождения Денгизкуль»;</li>\
          <li>РП «Внедрение автоматизированной системы управления технологическим процессом, включая контроль, регулирование, блокировку и противоаварийную защиту на НГКМ «Кокдумалак» и другие предпроектные работы по объектам добычи, сбора, компримирования, транспортировки, подготовки и переработки углеводородов.</li>\
      </ul>',
    text_en:
      '<p>\
          <strong>Abdurashidov Akbar Abdurasulovich</strong> has been operating as the Chief Project Engineer of O‘ZLITINEFTGAZ JSC since January 2021.\
      </p>\
      <p>During the period of work under his leadership, the following projects have been successfully developed:</p>\
      <ul>\
          <li>RP "Refurbishment of the Shumak field";</li>\
          <li>RP "Refurbishment of the South Mubarek field";</li>\
          <li>RP "Refurbishment of the Eastmok deposit";</li>\
          <li>RP "Refurbishment of the Uzunshor deposit";</li>\
          <li>RP "Development of Dultatepa field";</li>\
          <li>RP "Development of the Khoji-Khairam field";</li>\
          <li>РП «Обустройство месторождения Янги Кызыл-Рабат»;</li>\
          <li>УТЭР «Модернизация ГНКС Келлог для выработки электроэнергии»;</li>\
          <li>НИР «Ведомственные правила по охране линейной части магистральных газопроводов «Узбекистан-Китай».</li>\
          <li>РП «Дообустройство месторождения Каракум со строительством газопровода»;</li>\
          <li>РП «Дообустройство месторождений Северный Мубарек, Западный Тегермен, Тегермен, Северный Уртабулак, Умид»;</li>\
          <li>РП «Обустройство скважин месторождения Западный Тегермен»;</li>\
          <li>РП «Дообустройство месторождения Северный Мубарек с расширением системы сбора, подготовки и транспорта газа»;</li>\
          <li>РП «Строительство газопровода от СП-2 Южный Мубарек до МГПЗ»;</li>\
          <li>РП «Дообустройство месторождения Чулкувар со строительством установки предварительной подготовки газа (УППГ) Чулкувар»;</li>\
          <li>РП «Дообустройство месторождений Южный Мубарек, Янги Кизилработ, Южный Памук, Денгизкуль»;</li>\
          <li>РП «Строительство газопровода-коллектора от месторождения Даяхатын до Газлийского ГПЗ»;</li>\
      </ul></br>\
      <p><strong>Текущие проекты (2022г.):</strong></p>\
      <ul>\
          <li>ТЭО проекта «Поддержание добычи природного газа и газового конденсата по месторождениям АО «Узбекнефтегаз» на 2022-2026 годы» (по объектам Мубарекского НГДУ);</li>\
          <li>РП «Обустройство скважин № 324, 325, 326, 327, 328, 329, 330 месторождения Зеварды, № 239 месторождения Уртабулак, № 9 месторождения Западный Тегермен, № 12 месторождения Тегермен»;</li>\
          <li>РП «Обустройство месторождения Северный Тегермен»;</li>\
          <li>РП «Обустройство месторождений Ижобат и Уртабулак (терриген)»;</li>\
          <li>РП «Обустройство месторождения Уртабулак скважины №240, 241»;</li>\
          <li>РП «Модернизация системы сбора и транспорта газа Тегерменской группы месторождений»;</li>\
          <li>УТЭР «Выработка электроэнергии с помощью паровой турбины с использованием избыточной энергии пара в котельной №2 Мубарекского ГПЗ»;</li>\
          <li>УТЭР «Установка детандер-генераторного агрегата в системе редуцирования газа на Мубарекском ГПЗ для выработки электроэнергии»;</li>\
          <li>НИР «Геолого-технический проект разработки участка Ходжасаят газоконденсатного месторождения (ГКМ) Денгизкуль, ГКМ Ходжадавлат и ГКМ Шаркий Алат с учетом наземного обустройства»;</li>\
          <li>АН «Строительство пожарных депо на месторождениях Северные Шады и Кувачи Алат»;</li>\
          <li>РП «Расширение газотранспортной системы Тегерменской группы месторождений»;</li>\
          <li>РП «Строительство установки предварительной подготовки газа на участке Ходжасаят газоконденсатного месторождения Денгизкуль»;</li>\
          <li>РП «Внедрение автоматизированной системы управления технологическим процессом, включая контроль, регулирование, блокировку и противоаварийную защиту на НГКМ «Кокдумалак» и другие предпроектные работы по объектам добычи, сбора, компримирования, транспортировки, подготовки и переработки углеводородов.</li>\
      </ul>',
    id: 2,
  },
  {
    image: "../assets/images/services/gipi-3.jpg",
    fullName_ru: "Хан Татьяна Брониславовна",
    fullName_uz: "Хан Татьяна Брониславовна",
    fullName_oz: "Xan Tatyana Bronislavovna",
    fullName_en: "Khan Tatyana Bronislavovna",
    position_ru: "ГИП",
    position_uz: "ГИП",
    position_oz: "ГИП",
    position_en: "ГИП",
    text_ru:
      "<p>\
          <strong>Хан Татьяна Браниславовна</strong> ведет свою деятельность в качестве Главного инженера проекта АО «O‘ZLITINEFTGAZ» с 2001 года.\
      </p>\
      <p>За период работы под ее руководством успешно разработаны следующие проекты, по которым осуществлено строительство и введены в эксплуатацию следующие объекты:</p>\
      <ul>\
          <li>«Реконструкция Бухарского колледжа нефтегазовой промышленности» (РП);</li>\
          <li>«Техническое перевооружения АТЦ «Автонефттранс» (РП);</li>\
          <li>«АБК «Бухаранефтегазавтотранс» (РП);</li>\
          <li>«Обустройство газоконденсатного месторождения Самантепе» (РП);</li>\
          <li>«Расширение УКПГ Восточный Бердах» (РП);</li>\
          <li>«Обустройство месторождения Сургиль. 1-ый пусковой комплекс» (РП);</li>\
          <li>«Реконструкция здания института «Узтяжнефтегазхимпроект» (РП);</li>\
          <li>«Реконструкция УППГ газа Денгизкуль со строительством ДКС» (ПТЭО, ТЭО, РП);</li>\
          <li>«Строительство завода по производству электронных приборов учета газа в г. Навои» (РП);</li>\
          <li>«Расширение мощности Завода «Узбекхиммаш» для производства крупногабаритного крупнотоннажного оборудования» (РП);</li>\
          <li>«Организация производства бытовых газовых баллонов для сжиженного газа, биогазовых установок и емкостного оборудования в Хорезмской области» (РП);</li>\
          <li>«Дообустройство газоконденсатных месторождений Гирсан и Чулкувар» (РП);</li>\
          <li>«Дообустройство месторождения Самантепе» (ПТЭО, РП);</li>\
          <li>«Дообустройство месторождения Уртабулак со строительством ДКС» (ПТЭО, ТЭО, РП);</li>\
          <li>«Внедрение автоматизированных систем контроля учета природного газа (в г.Ташкенте и Ташкентской области (ПТЭО);</li>\
          <li>«Дообустройство месторождения Самантепе с расширением ДКС Южный Уртабулак» (ПТЭО);</li>\
          <li>«Опорная база промысла на месторождении Джаркудук-Янги-Кизилча» (объект ООО «ЛУОК» (рабочей документации);</li>\
          <li>«Строительство комплекса ГПЗ и обустройство Кандымской группы месторождений» (ТЭО и рабочая документация в рамках проекта по Экспортному газопроводу и Водоводу);</li>\
          <li>«Дообустройство месторождения Самантепе» (РП-2020г.);</li>\
          <li>«Дообустройство месторождения Уртабулак» (РП-2021г.);</li>\
          <li>«Обустройство скважин месторождения Самантепе» (РП-2021г.).</li>\
      </ul></br>\
      <p><strong>Текущие проекты (2022г.):</strong></p>\
      <ul>\
          <li>РП «Реконструкция резервуарного парка Кокдумалак»;</li>\
          <li>РП «Строительство резервуара V-300м3 на нефтеналивном участке Серный завод»;</li>\
          <li>«Дообустройство месторождения Южный Кемачи со строительством дополнительного газопровода «Южный Кемачи – ДКС Кокдумалак»»;</li>\
          <li>РП «Обустройство месторождения Раззок бобо»;</li>\
          <li>РП «Обустройство месторождения Северный Шоды со строительством УППГ и газопровода 5,0 км»</li>\
          <li>РП «Модернизация системы сбора и транспорта газа Тегерменской группы месторождений»;</li>\
          <li>и другие предпроектные и проектные работы по объектам добычи, сбора, и транспортировки, подготовки углеводородов Мубарекского НГДУ, АО «Узтрансгаз» и СП ООО «Asia Trans Gas».</li>\
      </ul>",
    text_uz:
      "<p>\
          <strong>Хан Татьяна Браниславовна</strong> ведет свою деятельность в качестве Главного инженера проекта АО «O‘ZLITINEFTGAZ» с 2001 года.\
      </p>\
      <p>За период работы под ее руководством успешно разработаны следующие проекты, по которым осуществлено строительство и введены в эксплуатацию следующие объекты:</p>\
      <ul>\
          <li>«Реконструкция Бухарского колледжа нефтегазовой промышленности» (РП);</li>\
          <li>«Техническое перевооружения АТЦ «Автонефттранс» (РП);</li>\
          <li>«АБК «Бухаранефтегазавтотранс» (РП);</li>\
          <li>«Обустройство газоконденсатного месторождения Самантепе» (РП);</li>\
          <li>«Расширение УКПГ Восточный Бердах» (РП);</li>\
          <li>«Обустройство месторождения Сургиль. 1-ый пусковой комплекс» (РП);</li>\
          <li>«Реконструкция здания института «Узтяжнефтегазхимпроект» (РП);</li>\
          <li>«Реконструкция УППГ газа Денгизкуль со строительством ДКС» (ПТЭО, ТЭО, РП);</li>\
          <li>«Строительство завода по производству электронных приборов учета газа в г. Навои» (РП);</li>\
          <li>«Расширение мощности Завода «Узбекхиммаш» для производства крупногабаритного крупнотоннажного оборудования» (РП);</li>\
          <li>«Организация производства бытовых газовых баллонов для сжиженного газа, биогазовых установок и емкостного оборудования в Хорезмской области» (РП);</li>\
          <li>«Дообустройство газоконденсатных месторождений Гирсан и Чулкувар» (РП);</li>\
          <li>«Дообустройство месторождения Самантепе» (ПТЭО, РП);</li>\
          <li>«Дообустройство месторождения Уртабулак со строительством ДКС» (ПТЭО, ТЭО, РП);</li>\
          <li>«Внедрение автоматизированных систем контроля учета природного газа (в г.Ташкенте и Ташкентской области (ПТЭО);</li>\
          <li>«Дообустройство месторождения Самантепе с расширением ДКС Южный Уртабулак» (ПТЭО);</li>\
          <li>«Опорная база промысла на месторождении Джаркудук-Янги-Кизилча» (объект ООО «ЛУОК» (рабочей документации);</li>\
          <li>«Строительство комплекса ГПЗ и обустройство Кандымской группы месторождений» (ТЭО и рабочая документация в рамках проекта по Экспортному газопроводу и Водоводу);</li>\
          <li>«Дообустройство месторождения Самантепе» (РП-2020г.);</li>\
          <li>«Дообустройство месторождения Уртабулак» (РП-2021г.);</li>\
          <li>«Обустройство скважин месторождения Самантепе» (РП-2021г.).</li>\
      </ul></br>\
      <p><strong>Текущие проекты (2022г.):</strong></p>\
      <ul>\
          <li>РП «Реконструкция резервуарного парка Кокдумалак»;</li>\
          <li>РП «Строительство резервуара V-300м3 на нефтеналивном участке Серный завод»;</li>\
          <li>«Дообустройство месторождения Южный Кемачи со строительством дополнительного газопровода «Южный Кемачи – ДКС Кокдумалак»»;</li>\
          <li>РП «Обустройство месторождения Раззок бобо»;</li>\
          <li>РП «Обустройство месторождения Северный Шоды со строительством УППГ и газопровода 5,0 км»</li>\
          <li>РП «Модернизация системы сбора и транспорта газа Тегерменской группы месторождений»;</li>\
          <li>и другие предпроектные и проектные работы по объектам добычи, сбора, и транспортировки, подготовки углеводородов Мубарекского НГДУ, АО «Узтрансгаз» и СП ООО «Asia Trans Gas».</li>\
      </ul>",
    text_oz:
      "<p>\
          <strong>Хан Татьяна Браниславовна</strong> ведет свою деятельность в качестве Главного инженера проекта АО «O‘ZLITINEFTGAZ» с 2001 года.\
      </p>\
      <p>За период работы под ее руководством успешно разработаны следующие проекты, по которым осуществлено строительство и введены в эксплуатацию следующие объекты:</p>\
      <ul>\
          <li>«Реконструкция Бухарского колледжа нефтегазовой промышленности» (РП);</li>\
          <li>«Техническое перевооружения АТЦ «Автонефттранс» (РП);</li>\
          <li>«АБК «Бухаранефтегазавтотранс» (РП);</li>\
          <li>«Обустройство газоконденсатного месторождения Самантепе» (РП);</li>\
          <li>«Расширение УКПГ Восточный Бердах» (РП);</li>\
          <li>«Обустройство месторождения Сургиль. 1-ый пусковой комплекс» (РП);</li>\
          <li>«Реконструкция здания института «Узтяжнефтегазхимпроект» (РП);</li>\
          <li>«Реконструкция УППГ газа Денгизкуль со строительством ДКС» (ПТЭО, ТЭО, РП);</li>\
          <li>«Строительство завода по производству электронных приборов учета газа в г. Навои» (РП);</li>\
          <li>«Расширение мощности Завода «Узбекхиммаш» для производства крупногабаритного крупнотоннажного оборудования» (РП);</li>\
          <li>«Организация производства бытовых газовых баллонов для сжиженного газа, биогазовых установок и емкостного оборудования в Хорезмской области» (РП);</li>\
          <li>«Дообустройство газоконденсатных месторождений Гирсан и Чулкувар» (РП);</li>\
          <li>«Дообустройство месторождения Самантепе» (ПТЭО, РП);</li>\
          <li>«Дообустройство месторождения Уртабулак со строительством ДКС» (ПТЭО, ТЭО, РП);</li>\
          <li>«Внедрение автоматизированных систем контроля учета природного газа (в г.Ташкенте и Ташкентской области (ПТЭО);</li>\
          <li>«Дообустройство месторождения Самантепе с расширением ДКС Южный Уртабулак» (ПТЭО);</li>\
          <li>«Опорная база промысла на месторождении Джаркудук-Янги-Кизилча» (объект ООО «ЛУОК» (рабочей документации);</li>\
          <li>«Строительство комплекса ГПЗ и обустройство Кандымской группы месторождений» (ТЭО и рабочая документация в рамках проекта по Экспортному газопроводу и Водоводу);</li>\
          <li>«Дообустройство месторождения Самантепе» (РП-2020г.);</li>\
          <li>«Дообустройство месторождения Уртабулак» (РП-2021г.);</li>\
          <li>«Обустройство скважин месторождения Самантепе» (РП-2021г.).</li>\
      </ul></br>\
      <p><strong>Текущие проекты (2022г.):</strong></p>\
      <ul>\
          <li>РП «Реконструкция резервуарного парка Кокдумалак»;</li>\
          <li>РП «Строительство резервуара V-300м3 на нефтеналивном участке Серный завод»;</li>\
          <li>«Дообустройство месторождения Южный Кемачи со строительством дополнительного газопровода «Южный Кемачи – ДКС Кокдумалак»»;</li>\
          <li>РП «Обустройство месторождения Раззок бобо»;</li>\
          <li>РП «Обустройство месторождения Северный Шоды со строительством УППГ и газопровода 5,0 км»</li>\
          <li>РП «Модернизация системы сбора и транспорта газа Тегерменской группы месторождений»;</li>\
          <li>и другие предпроектные и проектные работы по объектам добычи, сбора, и транспортировки, подготовки углеводородов Мубарекского НГДУ, АО «Узтрансгаз» и СП ООО «Asia Trans Gas».</li>\
      </ul>",
    text_en:
      "<p>\
          <strong>Хан Татьяна Браниславовна</strong> ведет свою деятельность в качестве Главного инженера проекта АО «O‘ZLITINEFTGAZ» с 2001 года.\
      </p>\
      <p>За период работы под ее руководством успешно разработаны следующие проекты, по которым осуществлено строительство и введены в эксплуатацию следующие объекты:</p>\
      <ul>\
          <li>«Реконструкция Бухарского колледжа нефтегазовой промышленности» (РП);</li>\
          <li>«Техническое перевооружения АТЦ «Автонефттранс» (РП);</li>\
          <li>«АБК «Бухаранефтегазавтотранс» (РП);</li>\
          <li>«Обустройство газоконденсатного месторождения Самантепе» (РП);</li>\
          <li>«Расширение УКПГ Восточный Бердах» (РП);</li>\
          <li>«Обустройство месторождения Сургиль. 1-ый пусковой комплекс» (РП);</li>\
          <li>«Реконструкция здания института «Узтяжнефтегазхимпроект» (РП);</li>\
          <li>«Реконструкция УППГ газа Денгизкуль со строительством ДКС» (ПТЭО, ТЭО, РП);</li>\
          <li>«Строительство завода по производству электронных приборов учета газа в г. Навои» (РП);</li>\
          <li>«Расширение мощности Завода «Узбекхиммаш» для производства крупногабаритного крупнотоннажного оборудования» (РП);</li>\
          <li>«Организация производства бытовых газовых баллонов для сжиженного газа, биогазовых установок и емкостного оборудования в Хорезмской области» (РП);</li>\
          <li>«Дообустройство газоконденсатных месторождений Гирсан и Чулкувар» (РП);</li>\
          <li>«Дообустройство месторождения Самантепе» (ПТЭО, РП);</li>\
          <li>«Дообустройство месторождения Уртабулак со строительством ДКС» (ПТЭО, ТЭО, РП);</li>\
          <li>«Внедрение автоматизированных систем контроля учета природного газа (в г.Ташкенте и Ташкентской области (ПТЭО);</li>\
          <li>«Дообустройство месторождения Самантепе с расширением ДКС Южный Уртабулак» (ПТЭО);</li>\
          <li>«Опорная база промысла на месторождении Джаркудук-Янги-Кизилча» (объект ООО «ЛУОК» (рабочей документации);</li>\
          <li>«Строительство комплекса ГПЗ и обустройство Кандымской группы месторождений» (ТЭО и рабочая документация в рамках проекта по Экспортному газопроводу и Водоводу);</li>\
          <li>«Дообустройство месторождения Самантепе» (РП-2020г.);</li>\
          <li>«Дообустройство месторождения Уртабулак» (РП-2021г.);</li>\
          <li>«Обустройство скважин месторождения Самантепе» (РП-2021г.).</li>\
      </ul></br>\
      <p><strong>Текущие проекты (2022г.):</strong></p>\
      <ul>\
          <li>РП «Реконструкция резервуарного парка Кокдумалак»;</li>\
          <li>РП «Строительство резервуара V-300м3 на нефтеналивном участке Серный завод»;</li>\
          <li>«Дообустройство месторождения Южный Кемачи со строительством дополнительного газопровода «Южный Кемачи – ДКС Кокдумалак»»;</li>\
          <li>РП «Обустройство месторождения Раззок бобо»;</li>\
          <li>РП «Обустройство месторождения Северный Шоды со строительством УППГ и газопровода 5,0 км»</li>\
          <li>РП «Модернизация системы сбора и транспорта газа Тегерменской группы месторождений»;</li>\
          <li>и другие предпроектные и проектные работы по объектам добычи, сбора, и транспортировки, подготовки углеводородов Мубарекского НГДУ, АО «Узтрансгаз» и СП ООО «Asia Trans Gas».</li>\
      </ul>",
    id: 3,
  },
  // {
  //   image: "../assets/images/services/gipi-4.jpg",
  //   fullName_ru: "Акбаров Дилмурод Бахтиярович",
  //   fullName_uz: "Акбаров Дилмурод Бахтиярович",
  //   fullName_oz: "Akbarov Dilmurod Baxtiyarovich",
  //   fullName_en: "Akbarov Dilmurod Bakhtiyarovich",
  //   position_ru: "ГИП",
  //   position_uz: "ГИП",
  //   position_oz: "ГИП",
  //   position_en: "ГИП",
  //   text_ru:
  //     "<p>\
  //                   <strong>Акбаров Дильмурод Бахтиярович</strong> ведет свою деятельность в качестве Главного инженера проекта АО «O‘ZLITINEFTGAZ» с января 2018 года.\
  //               </p>\
  //               <p>За период работы под его руководством успешно разработаны следующие проекты: - РП «Обустройство скважин №1055, 1056, 1057, 1058 участка Хаузак и №1081 западной части участка Шады Денгизкульского месторождения»;</p>\
  //               <ul>\
  //                   <li>РП «Ранний газ Кандымской группы месторождений и обустройство Северной части участка Шады»;</li>\
  //                   <li>РП «Строительство газопровода для подачи газа месторождений Умид, Жейнов и Пирназар на ДКС-2,3 Кокдумалак»;</li>\
  //                   <li>РД «Дообустройство месторождения Алан со строительством дожимной компрессорной станции (ДКС)»;</li>\
  //                   <li>РД «Дообустройство месторождения Южный Кемачи»;</li>\
  //                   <li>РД «Узлы ввода метанола на УППГ Северные Шады и УППГ Кувачи Алат проекта Ранний газ Кандымской группы месторождений и обустройство северной части участка Шады. Первая очередь строительства»;</li>\
  //                   <li>РД «Обустройство и подключение 50 скважин к существующим ГСП 1-6 на месторождении Сургил»;</li>\
  //                   <li>РД «Строительство нефтеперерабатывающего комплекса в Джизакской области». Нефтепровод»;</li>\
  //                   <li>ПТЭО «Строительство 4- й нитки газопровода «Узбекистан – Китай»;</li>\
  //                   <li>ПТЭО «Модернизация и реконструкция агрегатов Бухарского НПЗ».</li>\
  //               </ul></br>\
  //               <p><strong>Текущие проекты (2022г.):</strong></p>\
  //               <ul>\
  //                   <li>ТЭО «Комплекный Дообустроства месторождений Газлийского региона»</li>\
  //                   <li>ТЭО «Комплексного проекта «Поддержание добычи природного газа и газового конденсата по месторождениям АО «Узбекнефтегаз» на 2022-2026 годы.»»</li>\
  //               </ul>",
  //   text_uz:
  //     "<p>\
  //                   <strong>Акбаров Дильмурод Бахтиярович</strong> ведет свою деятельность в качестве Главного инженера проекта АО «O‘ZLITINEFTGAZ» с января 2018 года.\
  //               </p>\
  //               <p>За период работы под его руководством успешно разработаны следующие проекты: - РП «Обустройство скважин №1055, 1056, 1057, 1058 участка Хаузак и №1081 западной части участка Шады Денгизкульского месторождения»;</p>\
  //               <ul>\
  //                   <li>РП «Ранний газ Кандымской группы месторождений и обустройство Северной части участка Шады»;</li>\
  //                   <li>РП «Строительство газопровода для подачи газа месторождений Умид, Жейнов и Пирназар на ДКС-2,3 Кокдумалак»;</li>\
  //                   <li>РД «Дообустройство месторождения Алан со строительством дожимной компрессорной станции (ДКС)»;</li>\
  //                   <li>РД «Дообустройство месторождения Южный Кемачи»;</li>\
  //                   <li>РД «Узлы ввода метанола на УППГ Северные Шады и УППГ Кувачи Алат проекта Ранний газ Кандымской группы месторождений и обустройство северной части участка Шады. Первая очередь строительства»;</li>\
  //                   <li>РД «Обустройство и подключение 50 скважин к существующим ГСП 1-6 на месторождении Сургил»;</li>\
  //                   <li>РД «Строительство нефтеперерабатывающего комплекса в Джизакской области». Нефтепровод»;</li>\
  //                   <li>ПТЭО «Строительство 4- й нитки газопровода «Узбекистан – Китай»;</li>\
  //                   <li>ПТЭО «Модернизация и реконструкция агрегатов Бухарского НПЗ».</li>\
  //               </ul></br>\
  //               <p><strong>Текущие проекты (2022г.):</strong></p>\
  //               <ul>\
  //                   <li>ТЭО «Комплекный Дообустроства месторождений Газлийского региона»</li>\
  //                   <li>ТЭО «Комплексного проекта «Поддержание добычи природного газа и газового конденсата по месторождениям АО «Узбекнефтегаз» на 2022-2026 годы.»»</li>\
  //               </ul>",
  //   text_oz:
  //     "<p>\
  //                   <strong>Акбаров Дильмурод Бахтиярович</strong> ведет свою деятельность в качестве Главного инженера проекта АО «O‘ZLITINEFTGAZ» с января 2018 года.\
  //               </p>\
  //               <p>За период работы под его руководством успешно разработаны следующие проекты: - РП «Обустройство скважин №1055, 1056, 1057, 1058 участка Хаузак и №1081 западной части участка Шады Денгизкульского месторождения»;</p>\
  //               <ul>\
  //                   <li>РП «Ранний газ Кандымской группы месторождений и обустройство Северной части участка Шады»;</li>\
  //                   <li>РП «Строительство газопровода для подачи газа месторождений Умид, Жейнов и Пирназар на ДКС-2,3 Кокдумалак»;</li>\
  //                   <li>РД «Дообустройство месторождения Алан со строительством дожимной компрессорной станции (ДКС)»;</li>\
  //                   <li>РД «Дообустройство месторождения Южный Кемачи»;</li>\
  //                   <li>РД «Узлы ввода метанола на УППГ Северные Шады и УППГ Кувачи Алат проекта Ранний газ Кандымской группы месторождений и обустройство северной части участка Шады. Первая очередь строительства»;</li>\
  //                   <li>РД «Обустройство и подключение 50 скважин к существующим ГСП 1-6 на месторождении Сургил»;</li>\
  //                   <li>РД «Строительство нефтеперерабатывающего комплекса в Джизакской области». Нефтепровод»;</li>\
  //                   <li>ПТЭО «Строительство 4- й нитки газопровода «Узбекистан – Китай»;</li>\
  //                   <li>ПТЭО «Модернизация и реконструкция агрегатов Бухарского НПЗ».</li>\
  //               </ul></br>\
  //               <p><strong>Текущие проекты (2022г.):</strong></p>\
  //               <ul>\
  //                   <li>ТЭО «Комплекный Дообустроства месторождений Газлийского региона»</li>\
  //                   <li>ТЭО «Комплексного проекта «Поддержание добычи природного газа и газового конденсата по месторождениям АО «Узбекнефтегаз» на 2022-2026 годы.»»</li>\
  //               </ul>",
  //   text_en:
  //     "<p>\
  //                   <strong>Акбаров Дильмурод Бахтиярович</strong> ведет свою деятельность в качестве Главного инженера проекта АО «O‘ZLITINEFTGAZ» с января 2018 года.\
  //               </p>\
  //               <p>За период работы под его руководством успешно разработаны следующие проекты: - РП «Обустройство скважин №1055, 1056, 1057, 1058 участка Хаузак и №1081 западной части участка Шады Денгизкульского месторождения»;</p>\
  //               <ul>\
  //                   <li>РП «Ранний газ Кандымской группы месторождений и обустройство Северной части участка Шады»;</li>\
  //                   <li>РП «Строительство газопровода для подачи газа месторождений Умид, Жейнов и Пирназар на ДКС-2,3 Кокдумалак»;</li>\
  //                   <li>РД «Дообустройство месторождения Алан со строительством дожимной компрессорной станции (ДКС)»;</li>\
  //                   <li>РД «Дообустройство месторождения Южный Кемачи»;</li>\
  //                   <li>РД «Узлы ввода метанола на УППГ Северные Шады и УППГ Кувачи Алат проекта Ранний газ Кандымской группы месторождений и обустройство северной части участка Шады. Первая очередь строительства»;</li>\
  //                   <li>РД «Обустройство и подключение 50 скважин к существующим ГСП 1-6 на месторождении Сургил»;</li>\
  //                   <li>РД «Строительство нефтеперерабатывающего комплекса в Джизакской области». Нефтепровод»;</li>\
  //                   <li>ПТЭО «Строительство 4- й нитки газопровода «Узбекистан – Китай»;</li>\
  //                   <li>ПТЭО «Модернизация и реконструкция агрегатов Бухарского НПЗ».</li>\
  //               </ul></br>\
  //               <p><strong>Текущие проекты (2022г.):</strong></p>\
  //               <ul>\
  //                   <li>ТЭО «Комплекный Дообустроства месторождений Газлийского региона»</li>\
  //                   <li>ТЭО «Комплексного проекта «Поддержание добычи природного газа и газового конденсата по месторождениям АО «Узбекнефтегаз» на 2022-2026 годы.»»</li>\
  //               </ul>",
  //   id: 4,
  // },
  {
    image: "../assets/images/services/gipi-8.jpg",
    fullName_ru: "Гозиев Ахмаджон Адхамджанович",
    fullName_uz: "Гозиев Ахмаджон Адхамджанович",
    fullName_oz: "Goziyev Axmadjon Adhamdjanovich",
    fullName_en: "Goziev Akhmadjon Adhamdzhanovich",
    position_ru: "ГИП",
    position_uz: "ГИП",
    position_oz: "ГИП",
    position_en: "ГИП",
    text_ru:
        "<p>\
            <strong>Гозиев Ахмаджон Адхамжонович</strong> ведет свою деятельность в качестве Главного инженера проекта АО «O‘ZLITINEFTGAZ» с января 2014 года.\
        </p>\
        <p>За период работы под его руководством успешно разработаны следующие проекты:</p>\
        <ul>\
            <li>РП «Производство синтетического жидкого топлива на базе очищенного метан Шуртанского ГХК». Внешнее газоснабжение;</li>\
            <li>РП «Производство синтетического жидкого топлива на базе очищенного метан Шуртанского ГХК». Внешнее водоснабжение;</li>\
            <li>ТЭО проекта «Производство синтетического жидкого топлива на базе очищенного метан Шуртанского ГХК»;</li>\
            <li>РП «Реконструкция УКПГ Култак»;</li>\
            <li>РП «Организация автоматизированной системы контроля уровня и учета светлых нефтепродуктов, находящихся в резервуарных емкостях комбината «Озодлик»;</li>\
            <li>ТЭО проекта «Дообустройство месторождений Шаркий Бердак и Шимолий Бердак со строительством ДКС»;</li>\
            <li>РД «Дообустройство месторождений Шаркий Бердак и Шимолий Бердак со строительством ДКС (конденсатопровод от УКПГ Шакий Бердак до точки врезки конденсатопровода УКПГ Сургиль-УГХК)»;</li>\
            <li>и другие.</li>\
        </ul></br>\
        <p><strong>Текущие проекты (2022 г.):</strong></p>\
        <ul>\
            <li>РП «Дообустройство месторождения Куйи Сургиль скважины № 5,6,14,16,17,18,19,20,21,23»;</li>\
            <li>РП «Обустройство скважин Шагырлик № 1, № 2 и № 3»;</li>\
            <li>РП «Реконструкция установки получения серы с заменой технологических печей и котлов утилизаторов Шуртанского НГДУ»;</li>\
            <li>РП «Расширение УКПГ Арслан со строительством III-й технологической нитки»;</li>\
            <li>	и другие предпроектные и проектные работы по объектам добычи, сбора, компримирования, транспортировки, подготовки углеводородов Устюртского ГДУ, а также Мубарекского НГДУ.</li>\
        </ul>",
    text_uz:
        "<p>\
            <strong>Гозиев Ахмаджон Адхамжонович</strong> ведет свою деятельность в качестве Главного инженера проекта АО «O‘ZLITINEFTGAZ» с января 2014 года.\
        </p>\
        <p>За период работы под его руководством успешно разработаны следующие проекты:</p>\
        <ul>\
            <li>РП «Производство синтетического жидкого топлива на базе очищенного метан Шуртанского ГХК». Внешнее газоснабжение;</li>\
            <li>РП «Производство синтетического жидкого топлива на базе очищенного метан Шуртанского ГХК». Внешнее водоснабжение;</li>\
            <li>ТЭО проекта «Производство синтетического жидкого топлива на базе очищенного метан Шуртанского ГХК»;</li>\
            <li>РП «Реконструкция УКПГ Култак»;</li>\
            <li>РП «Организация автоматизированной системы контроля уровня и учета светлых нефтепродуктов, находящихся в резервуарных емкостях комбината «Озодлик»;</li>\
            <li>ТЭО проекта «Дообустройство месторождений Шаркий Бердак и Шимолий Бердак со строительством ДКС»;</li>\
            <li>РД «Дообустройство месторождений Шаркий Бердак и Шимолий Бердак со строительством ДКС (конденсатопровод от УКПГ Шакий Бердак до точки врезки конденсатопровода УКПГ Сургиль-УГХК)»;</li>\
            <li>и другие.</li>\
        </ul></br>\
        <p><strong>Текущие проекты (2022 г.):</strong></p>\
        <ul>\
            <li>РП «Дообустройство месторождения Куйи Сургиль скважины № 5,6,14,16,17,18,19,20,21,23»;</li>\
            <li>РП «Обустройство скважин Шагырлик № 1, № 2 и № 3»;</li>\
            <li>РП «Реконструкция установки получения серы с заменой технологических печей и котлов утилизаторов Шуртанского НГДУ»;</li>\
            <li>РП «Расширение УКПГ Арслан со строительством III-й технологической нитки»;</li>\
            <li>	и другие предпроектные и проектные работы по объектам добычи, сбора, компримирования, транспортировки, подготовки углеводородов Устюртского ГДУ, а также Мубарекского НГДУ.</li>\
        </ul>",
    text_oz:
        "<p>\
            <strong>Гозиев Ахмаджон Адхамжонович</strong> ведет свою деятельность в качестве Главного инженера проекта АО «O‘ZLITINEFTGAZ» с января 2014 года.\
        </p>\
        <p>За период работы под его руководством успешно разработаны следующие проекты:</p>\
        <ul>\
            <li>РП «Производство синтетического жидкого топлива на базе очищенного метан Шуртанского ГХК». Внешнее газоснабжение;</li>\
            <li>РП «Производство синтетического жидкого топлива на базе очищенного метан Шуртанского ГХК». Внешнее водоснабжение;</li>\
            <li>ТЭО проекта «Производство синтетического жидкого топлива на базе очищенного метан Шуртанского ГХК»;</li>\
            <li>РП «Реконструкция УКПГ Култак»;</li>\
            <li>РП «Организация автоматизированной системы контроля уровня и учета светлых нефтепродуктов, находящихся в резервуарных емкостях комбината «Озодлик»;</li>\
            <li>ТЭО проекта «Дообустройство месторождений Шаркий Бердак и Шимолий Бердак со строительством ДКС»;</li>\
            <li>РД «Дообустройство месторождений Шаркий Бердак и Шимолий Бердак со строительством ДКС (конденсатопровод от УКПГ Шакий Бердак до точки врезки конденсатопровода УКПГ Сургиль-УГХК)»;</li>\
            <li>и другие.</li>\
        </ul></br>\
        <p><strong>Текущие проекты (2022 г.):</strong></p>\
        <ul>\
            <li>РП «Дообустройство месторождения Куйи Сургиль скважины № 5,6,14,16,17,18,19,20,21,23»;</li>\
            <li>РП «Обустройство скважин Шагырлик № 1, № 2 и № 3»;</li>\
            <li>РП «Реконструкция установки получения серы с заменой технологических печей и котлов утилизаторов Шуртанского НГДУ»;</li>\
            <li>РП «Расширение УКПГ Арслан со строительством III-й технологической нитки»;</li>\
            <li>	и другие предпроектные и проектные работы по объектам добычи, сбора, компримирования, транспортировки, подготовки углеводородов Устюртского ГДУ, а также Мубарекского НГДУ.</li>\
        </ul>",
    text_en:
        "<p>\
            <strong>Гозиев Ахмаджон Адхамжонович</strong> ведет свою деятельность в качестве Главного инженера проекта АО «O‘ZLITINEFTGAZ» с января 2014 года.\
        </p>\
        <p>За период работы под его руководством успешно разработаны следующие проекты:</p>\
        <ul>\
            <li>РП «Производство синтетического жидкого топлива на базе очищенного метан Шуртанского ГХК». Внешнее газоснабжение;</li>\
            <li>РП «Производство синтетического жидкого топлива на базе очищенного метан Шуртанского ГХК». Внешнее водоснабжение;</li>\
            <li>ТЭО проекта «Производство синтетического жидкого топлива на базе очищенного метан Шуртанского ГХК»;</li>\
            <li>РП «Реконструкция УКПГ Култак»;</li>\
            <li>РП «Организация автоматизированной системы контроля уровня и учета светлых нефтепродуктов, находящихся в резервуарных емкостях комбината «Озодлик»;</li>\
            <li>ТЭО проекта «Дообустройство месторождений Шаркий Бердак и Шимолий Бердак со строительством ДКС»;</li>\
            <li>РД «Дообустройство месторождений Шаркий Бердак и Шимолий Бердак со строительством ДКС (конденсатопровод от УКПГ Шакий Бердак до точки врезки конденсатопровода УКПГ Сургиль-УГХК)»;</li>\
            <li>и другие.</li>\
        </ul></br>\
        <p><strong>Текущие проекты (2022 г.):</strong></p>\
        <ul>\
            <li>РП «Дообустройство месторождения Куйи Сургиль скважины № 5,6,14,16,17,18,19,20,21,23»;</li>\
            <li>РП «Обустройство скважин Шагырлик № 1, № 2 и № 3»;</li>\
            <li>РП «Реконструкция установки получения серы с заменой технологических печей и котлов утилизаторов Шуртанского НГДУ»;</li>\
            <li>РП «Расширение УКПГ Арслан со строительством III-й технологической нитки»;</li>\
            <li>	и другие предпроектные и проектные работы по объектам добычи, сбора, компримирования, транспортировки, подготовки углеводородов Устюртского ГДУ, а также Мубарекского НГДУ.</li>\
        </ul>",
    id: 8,
  },
  // {
  //   image: "../assets/images/services/gipi-9.jpg",
  //   fullName_ru: "Аликулов Дилмурад Кушакович",
  //   fullName_uz: "Аликулов Дилмурад Кушакович",
  //   fullName_oz: "Alikulov Dilmurad Kushakovich",
  //   fullName_en: "Alikulov Dilmurad Kushakovich",
  //   position_ru: "ГИП",
  //   position_uz: "ГИП",
  //   position_oz: "ГИП",
  //   position_en: "ГИП",
  //   text_ru:
  //       '<p>\
  //           <strong>Аликулов Дилмурад Кушакович</strong> вел свою деятельность в качестве Главного инженера проекта АО «O‘ZLITINEFTGAZ» с сентября 2023 года.\
  //       </p>\
  //       <p>За период работы под его руководством успешно разработаны следующие проекты:</p>\
  //       <ul>\
  //           <li>«Реконструкция Асфальтового завода в Ханабаде(Дискавери инвест)»;</li>\
  //           <li>«Организация управления строительства мостов и путепроводов в Ташкенте»</li>\
  //           <li>«Проект Ташкент-Сити»;</li>\
  //           <li>«Строительство УППГ на сборных пунктах газа Бт-34, СП-20, СП-26. Месторождения Шуртан».;</li>\
  //           <li>«Строительство устьевой компрессорной станции УКС-1, УКС-2, УКС-3 для сборных пунктов СП-20,24,26, Бт-16,17,34 месторождения Шуртан».;</li>\
  //           <li>«Дообустройство месторождения Кульбешкак скважины №65,66 Газлийского НГДУ»;</li>\
  //           <li>«Строительство газопровода сжиженного углеводородного газа между заводами GTL - ШГХК».</li>\
  //           <li>«Расширение газотранспортной системы газосборных пунктов м/р Шуртан». </li>\
  //           <li>«Расширение коллектора от СП-4 до УППГ-2 со строительством дополнительного коллектора д-426мм на м/р Шуртан протяжённостью 7,4км».</li>\
  //           <li>«Монтаж паропровода высокого давления,трубопровода пароконденсата низкого давления и редукционно-охладительной установки от ООО «Uzbekistan GTL» до ШГХК»</li>\
  //           <li>«Строительство коллектора от м/р Ниёз до УППГ-1 м/р Шуртан д-168мм протяжённостью 26 км».</li>\
  //       </ul>',
  //   text_uz:
  //       '<p>\
  //           <strong>Аликулов Дилмурад Кушакович</strong> вел свою деятельность в качестве Главного инженера проекта АО «O‘ZLITINEFTGAZ» с апреля 2023 года.\
  //       </p>\
  //       <p>За период работы под его руководством успешно разработаны следующие проекты:</p>\
  //       <ul>\
  //           <li>«Реконструкция Асфальтового завода в Ханабаде(Дискавери инвест)»;</li>\
  //           <li>«Организация управления строительства мостов и путепроводов в Ташкенте»</li>\
  //           <li>«Проект Ташкент-Сити»;</li>\
  //           <li>«Строительство УППГ на сборных пунктах газа Бт-34, СП-20, СП-26. Месторождения Шуртан».;</li>\
  //           <li>«Строительство устьевой компрессорной станции УКС-1, УКС-2, УКС-3 для сборных пунктов СП-20,24,26, Бт-16,17,34 месторождения Шуртан».;</li>\
  //           <li>«Дообустройство месторождения Кульбешкак скважины №65,66 Газлийского НГДУ»;</li>\
  //           <li>«Строительство газопровода сжиженного углеводородного газа между заводами GTL - ШГХК».</li>\
  //           <li>«Расширение газотранспортной системы газосборных пунктов м/р Шуртан». </li>\
  //           <li>«Расширение коллектора от СП-4 до УППГ-2 со строительством дополнительного коллектора д-426мм на м/р Шуртан протяжённостью 7,4км».</li>\
  //           <li>«Монтаж паропровода высокого давления,трубопровода пароконденсата низкого давления и редукционно-охладительной установки от ООО «Uzbekistan GTL» до ШГХК»</li>\
  //           <li>«Строительство коллектора от м/р Ниёз до УППГ-1 м/р Шуртан д-168мм протяжённостью 26 км».</li>\
  //       </ul>',
  //   text_oz:
  //       '<p>\
  //           <strong>Аликулов Дилмурад Кушакович</strong> вел свою деятельность в качестве Главного инженера проекта АО «O‘ZLITINEFTGAZ» с апреля 2023 года.\
  //       </p>\
  //       <p>За период работы под его руководством успешно разработаны следующие проекты:</p>\
  //       <ul>\
  //           <li>«Реконструкция Асфальтового завода в Ханабаде(Дискавери инвест)»;</li>\
  //           <li>«Организация управления строительства мостов и путепроводов в Ташкенте»</li>\
  //           <li>«Проект Ташкент-Сити»;</li>\
  //           <li>«Строительство УППГ на сборных пунктах газа Бт-34, СП-20, СП-26. Месторождения Шуртан».;</li>\
  //           <li>«Строительство устьевой компрессорной станции УКС-1, УКС-2, УКС-3 для сборных пунктов СП-20,24,26, Бт-16,17,34 месторождения Шуртан».;</li>\
  //           <li>«Дообустройство месторождения Кульбешкак скважины №65,66 Газлийского НГДУ»;</li>\
  //           <li>«Строительство газопровода сжиженного углеводородного газа между заводами GTL - ШГХК».</li>\
  //           <li>«Расширение газотранспортной системы газосборных пунктов м/р Шуртан». </li>\
  //           <li>«Расширение коллектора от СП-4 до УППГ-2 со строительством дополнительного коллектора д-426мм на м/р Шуртан протяжённостью 7,4км».</li>\
  //           <li>«Монтаж паропровода высокого давления,трубопровода пароконденсата низкого давления и редукционно-охладительной установки от ООО «Uzbekistan GTL» до ШГХК»</li>\
  //           <li>«Строительство коллектора от м/р Ниёз до УППГ-1 м/р Шуртан д-168мм протяжённостью 26 км».</li>\
  //       </ul>',
  //   text_en:
  //       '<p>\
  //           <strong>Аликулов Дилмурад Кушакович</strong> вел свою деятельность в качестве Главного инженера проекта АО «O‘ZLITINEFTGAZ» с апреля 2023 года.\
  //       </p>\
  //       <p>За период работы под его руководством успешно разработаны следующие проекты:</p>\
  //       <ul>\
  //           <li>«Реконструкция Асфальтового завода в Ханабаде(Дискавери инвест)»;</li>\
  //           <li>«Организация управления строительства мостов и путепроводов в Ташкенте»</li>\
  //           <li>«Проект Ташкент-Сити»;</li>\
  //           <li>«Строительство УППГ на сборных пунктах газа Бт-34, СП-20, СП-26. Месторождения Шуртан».;</li>\
  //           <li>«Строительство устьевой компрессорной станции УКС-1, УКС-2, УКС-3 для сборных пунктов СП-20,24,26, Бт-16,17,34 месторождения Шуртан».;</li>\
  //           <li>«Дообустройство месторождения Кульбешкак скважины №65,66 Газлийского НГДУ»;</li>\
  //           <li>«Строительство газопровода сжиженного углеводородного газа между заводами GTL - ШГХК».</li>\
  //           <li>«Расширение газотранспортной системы газосборных пунктов м/р Шуртан». </li>\
  //           <li>«Расширение коллектора от СП-4 до УППГ-2 со строительством дополнительного коллектора д-426мм на м/р Шуртан протяжённостью 7,4км».</li>\
  //           <li>«Монтаж паропровода высокого давления,трубопровода пароконденсата низкого давления и редукционно-охладительной установки от ООО «Uzbekistan GTL» до ШГХК»</li>\
  //           <li>«Строительство коллектора от м/р Ниёз до УППГ-1 м/р Шуртан д-168мм протяжённостью 26 км».</li>\
  //       </ul>',
  //   id: 9,
  // },
  {
    image: "../assets/images/services/gipi-10.jpg",
    fullName_ru: "Абдураимов Ойбек Вахабович",
    fullName_uz: "Абдураимов Ойбек Вахабович",
    fullName_oz: "Abduraimov Oybek Vahabovich",
    fullName_en: "Abduraimov Oybek Vahabovich",
    position_ru: "ГИП",
    position_uz: "ГИП",
    position_oz: "ГИП",
    position_en: "ГИП",
    text_ru:
        "<p>\
            <strong>Абдураимов Ойбек Вахабович</strong> ведет свою деятельность в качестве Главного инженера проекта АО «O‘ZLITINEFTGAZ» с января 2018 года.\
        </p>\
        <p>За период работы под его руководством успешно разработаны следующие проекты:</p>\
        <ul>\
            <li>РП «Дообустройство месторождения Шуртан»;</li>\
            <li>РП «Дообустройство месторождения Юж.Тандырча со строительством ДКС»;</li>\
            <li>РП «Модернизация установки аминовой и циолитовой очистки газа ГС Шуртан»;</li>\
            <li>РП «Строительство объектов ООО «Шуртаннефтегаз»»;</li>\
            <li>РП «Модернизация существующих установок ДКС УППБС-3/1,2,5»;</li>\
            <li>РП «Капитальный ремонт по объектам Газлийского НГДУ АО «Узбекнефтегаз»»;</li>\
            <li>РП «Реконструкция конденсатного парка со строительством 2-х РВС по 2000 м3 на СОУ Учкыр»;</li>\
            <li>РП «Дообустройство Кумлинской группы месторождений со строительством ДКС»;</li>\
            <li>РП «Обустройство Янгиказганской группы месторождений со строительством ДКС»;</li>\
            <li>РП «Дообустройство месторождения Тайлок ООО «Газлинефтегаздобыча»»;</li>\
            <li>РП «Дообустройство месторождения Даяхатын»;</li>\
            <li>РП «Обустройство и дообустройтсво Ходжиказганской группы месторождений со строительством ДКС».</li>\
        </ul></br>\
        <p><strong>Текущие проекты (2022г.):</strong></p>\
        <ul>\
            <li>РП «Строительство сепарации газа на месторождении Учкыр и реконструкция замерных узлов на месторождениях Газлийского НГДУ»;</li>\
            <li>РП «Реконструкция 1-5 технологических ниток СОУ Газлийского ГПЗ»;</li>\
            <li>РП «Модернизация подстанции УППГ-35/6 на месторождении «Шуртан» Шуртанского НГДУ»;</li>\
            <li>РП «Модернизация объектов периметрального охранного ограждения Шуртанского НГДУ АО «Узбекнефтегаз»».</li>\
        </ul>",
    text_uz:
        "<p>\
            <strong>Абдураимов Ойбек Вахабович</strong> ведет свою деятельность в качестве Главного инженера проекта АО «O‘ZLITINEFTGAZ» с января 2018 года.\
        </p>\
        <p>За период работы под его руководством успешно разработаны следующие проекты:</p>\
        <ul>\
            <li>РП «Дообустройство месторождения Шуртан»;</li>\
            <li>РП «Дообустройство месторождения Юж.Тандырча со строительством ДКС»;</li>\
            <li>РП «Модернизация установки аминовой и циолитовой очистки газа ГС Шуртан»;</li>\
            <li>РП «Строительство объектов ООО «Шуртаннефтегаз»»;</li>\
            <li>РП «Модернизация существующих установок ДКС УППБС-3/1,2,5»;</li>\
            <li>РП «Капитальный ремонт по объектам Газлийского НГДУ АО «Узбекнефтегаз»»;</li>\
            <li>РП «Реконструкция конденсатного парка со строительством 2-х РВС по 2000 м3 на СОУ Учкыр»;</li>\
            <li>РП «Дообустройство Кумлинской группы месторождений со строительством ДКС»;</li>\
            <li>РП «Обустройство Янгиказганской группы месторождений со строительством ДКС»;</li>\
            <li>РП «Дообустройство месторождения Тайлок ООО «Газлинефтегаздобыча»»;</li>\
            <li>РП «Дообустройство месторождения Даяхатын»;</li>\
            <li>РП «Обустройство и дообустройтсво Ходжиказганской группы месторождений со строительством ДКС».</li>\
        </ul></br>\
        <p><strong>Текущие проекты (2022г.):</strong></p>\
        <ul>\
            <li>РП «Строительство сепарации газа на месторождении Учкыр и реконструкция замерных узлов на месторождениях Газлийского НГДУ»;</li>\
            <li>РП «Реконструкция 1-5 технологических ниток СОУ Газлийского ГПЗ»;</li>\
            <li>РП «Модернизация подстанции УППГ-35/6 на месторождении «Шуртан» Шуртанского НГДУ»;</li>\
            <li>РП «Модернизация объектов периметрального охранного ограждения Шуртанского НГДУ АО «Узбекнефтегаз»».</li>\
        </ul>",
    text_oz:
        "<p>\
            <strong>Абдураимов Ойбек Вахабович</strong> ведет свою деятельность в качестве Главного инженера проекта АО «O‘ZLITINEFTGAZ» с января 2018 года.\
        </p>\
        <p>За период работы под его руководством успешно разработаны следующие проекты:</p>\
        <ul>\
            <li>РП «Дообустройство месторождения Шуртан»;</li>\
            <li>РП «Дообустройство месторождения Юж.Тандырча со строительством ДКС»;</li>\
            <li>РП «Модернизация установки аминовой и циолитовой очистки газа ГС Шуртан»;</li>\
            <li>РП «Строительство объектов ООО «Шуртаннефтегаз»»;</li>\
            <li>РП «Модернизация существующих установок ДКС УППБС-3/1,2,5»;</li>\
            <li>РП «Капитальный ремонт по объектам Газлийского НГДУ АО «Узбекнефтегаз»»;</li>\
            <li>РП «Реконструкция конденсатного парка со строительством 2-х РВС по 2000 м3 на СОУ Учкыр»;</li>\
            <li>РП «Дообустройство Кумлинской группы месторождений со строительством ДКС»;</li>\
            <li>РП «Обустройство Янгиказганской группы месторождений со строительством ДКС»;</li>\
            <li>РП «Дообустройство месторождения Тайлок ООО «Газлинефтегаздобыча»»;</li>\
            <li>РП «Дообустройство месторождения Даяхатын»;</li>\
            <li>РП «Обустройство и дообустройтсво Ходжиказганской группы месторождений со строительством ДКС».</li>\
        </ul></br>\
        <p><strong>Текущие проекты (2022г.):</strong></p>\
        <ul>\
            <li>РП «Строительство сепарации газа на месторождении Учкыр и реконструкция замерных узлов на месторождениях Газлийского НГДУ»;</li>\
            <li>РП «Реконструкция 1-5 технологических ниток СОУ Газлийского ГПЗ»;</li>\
            <li>РП «Модернизация подстанции УППГ-35/6 на месторождении «Шуртан» Шуртанского НГДУ»;</li>\
            <li>РП «Модернизация объектов периметрального охранного ограждения Шуртанского НГДУ АО «Узбекнефтегаз»».</li>\
        </ul>",
    text_en:
        "<p>\
            <strong>Абдураимов Ойбек Вахабович</strong> ведет свою деятельность в качестве Главного инженера проекта АО «O‘ZLITINEFTGAZ» с января 2018 года.\
        </p>\
        <p>За период работы под его руководством успешно разработаны следующие проекты:</p>\
        <ul>\
            <li>РП «Дообустройство месторождения Шуртан»;</li>\
            <li>РП «Дообустройство месторождения Юж.Тандырча со строительством ДКС»;</li>\
            <li>РП «Модернизация установки аминовой и циолитовой очистки газа ГС Шуртан»;</li>\
            <li>РП «Строительство объектов ООО «Шуртаннефтегаз»»;</li>\
            <li>РП «Модернизация существующих установок ДКС УППБС-3/1,2,5»;</li>\
            <li>РП «Капитальный ремонт по объектам Газлийского НГДУ АО «Узбекнефтегаз»»;</li>\
            <li>РП «Реконструкция конденсатного парка со строительством 2-х РВС по 2000 м3 на СОУ Учкыр»;</li>\
            <li>РП «Дообустройство Кумлинской группы месторождений со строительством ДКС»;</li>\
            <li>РП «Обустройство Янгиказганской группы месторождений со строительством ДКС»;</li>\
            <li>РП «Дообустройство месторождения Тайлок ООО «Газлинефтегаздобыча»»;</li>\
            <li>РП «Дообустройство месторождения Даяхатын»;</li>\
            <li>РП «Обустройство и дообустройтсво Ходжиказганской группы месторождений со строительством ДКС».</li>\
        </ul></br>\
        <p><strong>Текущие проекты (2022г.):</strong></p>\
        <ul>\
            <li>РП «Строительство сепарации газа на месторождении Учкыр и реконструкция замерных узлов на месторождениях Газлийского НГДУ»;</li>\
            <li>РП «Реконструкция 1-5 технологических ниток СОУ Газлийского ГПЗ»;</li>\
            <li>РП «Модернизация подстанции УППГ-35/6 на месторождении «Шуртан» Шуртанского НГДУ»;</li>\
            <li>РП «Модернизация объектов периметрального охранного ограждения Шуртанского НГДУ АО «Узбекнефтегаз»».</li>\
        </ul>",
    id: 10,
  },
  {
    image: "../assets/images/services/gipi-5.jpg",
    fullName_ru: "Исмаилов Равшан Шорустам ўғли",
    fullName_uz: "Исмаилов Равшан Шорустам ўғли",
    fullName_oz: "Ismailov Ravshan Shorustam",
    fullName_en: "Ismailov Ravshan Shorustam",
    position_ru: "Заместитель ГИПа",
    position_uz: "Заместитель ГИПа",
    position_oz: "Заместитель ГИПа",
    position_en: "Заместитель ГИПа",
    text_ru:
      "<p>\
          <strong>Исмаилов Равшан Шорустам угли</strong> ведет свою деятельность в качестве заместителя главного инженера проекта АО «O‘ZLITINEFTGAZ» с июля 2021 года.\
      </p>\
      <p>За период работы принимал участие в качестве заместителя ГИПа в выпуске следующих проектов:</p>\
      <ul>\
          <li>РП «Дообустройство месторождения Арслан Устюртского ГДУ»;</li>\
          <li>РП «Дообустройство месторождения Куйи Сургиль Устюртского ГДУ»;</li>\
          <li>РП «Обустройство скважин №1, №2 Западный Куйи Сургиль Устюртского ГДУ»;</li>\
          <li>РП «Обустройство месторождения Шимолий Бердак Устюртского ГДУ»;</li>\
          <li>РП «Обустройство скважин месторождения Алан Мубарекского НГДУ»;</li>\
          <li>и другие.</li>\
      </ul></br>\
      <p>Под его руководством успешно завершена работа по объекту РП «Реконструкция установки получения серы с заменой технологических печей и котлов утилизаторов Шуртанского НГДУ».</p>\
      <p><strong>Текущие проекты (2022г.):</strong></p>\
      <ul>\
          <li>РП «Расширение УКПГ Арслан со строительством III-й технологической нитки»;</li>\
          <li>РП «Строительство устьевых компрессорных станции на месторождении Шумак»;</li>\
          <li>ТЭО проекта «Комплексное дообустройство месторождений Газлийского региона»;</li>\
          <li>ТЭО Комплексного проекта «Поддержание добычи природного газа и газового конденсата по месторождениям АО «Узбекнефтегаз» на 2022-2026 годы».</li>\
          <li>и другие предпроектные и проектные работы по объектам добычи, сбора, компримирования, транспортировки, подготовки углеводородов Устюртского ГДУ, а также Мубарекского НГДУ.</li>\
      </ul>",
    text_uz:
      "<p>\
          <strong>Исмаилов Равшан Шорустам угли</strong> ведет свою деятельность в качестве заместителя главного инженера проекта АО «O‘ZLITINEFTGAZ» с июля 2021 года.\
      </p>\
      <p>За период работы принимал участие в качестве заместителя ГИПа в выпуске следующих проектов:</p>\
      <ul>\
          <li>РП «Дообустройство месторождения Арслан Устюртского ГДУ»;</li>\
          <li>РП «Дообустройство месторождения Куйи Сургиль Устюртского ГДУ»;</li>\
          <li>РП «Обустройство скважин №1, №2 Западный Куйи Сургиль Устюртского ГДУ»;</li>\
          <li>РП «Обустройство месторождения Шимолий Бердак Устюртского ГДУ»;</li>\
          <li>РП «Обустройство скважин месторождения Алан Мубарекского НГДУ»;</li>\
          <li>и другие.</li>\
      </ul></br>\
      <p>Под его руководством успешно завершена работа по объекту РП «Реконструкция установки получения серы с заменой технологических печей и котлов утилизаторов Шуртанского НГДУ».</p>\
      <p><strong>Текущие проекты (2022г.):</strong></p>\
      <ul>\
          <li>РП «Расширение УКПГ Арслан со строительством III-й технологической нитки»;</li>\
          <li>РП «Строительство устьевых компрессорных станции на месторождении Шумак»;</li>\
          <li>ТЭО проекта «Комплексное дообустройство месторождений Газлийского региона»;</li>\
          <li>ТЭО Комплексного проекта «Поддержание добычи природного газа и газового конденсата по месторождениям АО «Узбекнефтегаз» на 2022-2026 годы».</li>\
          <li>и другие предпроектные и проектные работы по объектам добычи, сбора, компримирования, транспортировки, подготовки углеводородов Устюртского ГДУ, а также Мубарекского НГДУ.</li>\
      </ul>",
    text_oz:
      "<p>\
          <strong>Исмаилов Равшан Шорустам угли</strong> ведет свою деятельность в качестве заместителя главного инженера проекта АО «O‘ZLITINEFTGAZ» с июля 2021 года.\
      </p>\
      <p>За период работы принимал участие в качестве заместителя ГИПа в выпуске следующих проектов:</p>\
      <ul>\
          <li>РП «Дообустройство месторождения Арслан Устюртского ГДУ»;</li>\
          <li>РП «Дообустройство месторождения Куйи Сургиль Устюртского ГДУ»;</li>\
          <li>РП «Обустройство скважин №1, №2 Западный Куйи Сургиль Устюртского ГДУ»;</li>\
          <li>РП «Обустройство месторождения Шимолий Бердак Устюртского ГДУ»;</li>\
          <li>РП «Обустройство скважин месторождения Алан Мубарекского НГДУ»;</li>\
          <li>и другие.</li>\
      </ul></br>\
      <p>Под его руководством успешно завершена работа по объекту РП «Реконструкция установки получения серы с заменой технологических печей и котлов утилизаторов Шуртанского НГДУ».</p>\
      <p><strong>Текущие проекты (2022г.):</strong></p>\
      <ul>\
          <li>РП «Расширение УКПГ Арслан со строительством III-й технологической нитки»;</li>\
          <li>РП «Строительство устьевых компрессорных станции на месторождении Шумак»;</li>\
          <li>ТЭО проекта «Комплексное дообустройство месторождений Газлийского региона»;</li>\
          <li>ТЭО Комплексного проекта «Поддержание добычи природного газа и газового конденсата по месторождениям АО «Узбекнефтегаз» на 2022-2026 годы».</li>\
          <li>и другие предпроектные и проектные работы по объектам добычи, сбора, компримирования, транспортировки, подготовки углеводородов Устюртского ГДУ, а также Мубарекского НГДУ.</li>\
      </ul>",
    text_en:
      "<p>\
          <strong>Исмаилов Равшан Шорустам угли</strong> ведет свою деятельность в качестве заместителя главного инженера проекта АО «O‘ZLITINEFTGAZ» с июля 2021 года.\
      </p>\
      <p>За период работы принимал участие в качестве заместителя ГИПа в выпуске следующих проектов:</p>\
      <ul>\
          <li>РП «Дообустройство месторождения Арслан Устюртского ГДУ»;</li>\
          <li>РП «Дообустройство месторождения Куйи Сургиль Устюртского ГДУ»;</li>\
          <li>РП «Обустройство скважин №1, №2 Западный Куйи Сургиль Устюртского ГДУ»;</li>\
          <li>РП «Обустройство месторождения Шимолий Бердак Устюртского ГДУ»;</li>\
          <li>РП «Обустройство скважин месторождения Алан Мубарекского НГДУ»;</li>\
          <li>и другие.</li>\
      </ul></br>\
      <p>Под его руководством успешно завершена работа по объекту РП «Реконструкция установки получения серы с заменой технологических печей и котлов утилизаторов Шуртанского НГДУ».</p>\
      <p><strong>Текущие проекты (2022г.):</strong></p>\
      <ul>\
          <li>РП «Расширение УКПГ Арслан со строительством III-й технологической нитки»;</li>\
          <li>РП «Строительство устьевых компрессорных станции на месторождении Шумак»;</li>\
          <li>ТЭО проекта «Комплексное дообустройство месторождений Газлийского региона»;</li>\
          <li>ТЭО Комплексного проекта «Поддержание добычи природного газа и газового конденсата по месторождениям АО «Узбекнефтегаз» на 2022-2026 годы».</li>\
          <li>и другие предпроектные и проектные работы по объектам добычи, сбора, компримирования, транспортировки, подготовки углеводородов Устюртского ГДУ, а также Мубарекского НГДУ.</li>\
      </ul>",
    id: 5,
  },
  {
    image: "../assets/images/services/gipi-6.jpg",
    fullName_ru: "Каюмов Нозимбек Дилшод угли",
    fullName_uz: "Каюмов Нозимбек Дилшод угли",
    fullName_oz: "Kayumov Nozimbek Dilshod ugli",
    fullName_en: "Kayumov Nozimbek Dilshod ugli",
    position_ru: "Заместитель ГИПа",
    text_ru:
      "<p>\
          <strong>Каюмов Нозимбек Дилшод угли</strong> ведет свою деятельность в качестве Заместителя Главного инженера проекта АО «O‘ZLITINEFTGAZ» с мая 2021 года.\
      </p>\
      <p>За период работы под его руководством успешно разработаны следующие проекты:</p>\
      <ul>\
          <li>РП «Дообустройство месторождения Даяхатын скважины № 85, 86, 87, 88, 89, 90, 91»;</li>\
          <li>РП «Дообустройство месторождения Даяхатын скважины № 93, 94, 95»;</li>\
          <li>РП «Дообустройство месторождения Даяхатын скважины № 96, 97, 98, 99, 100, 101»;</li>\
          <li>РП «Дообустройство месторождения Саватли скважины № 11»;</li>\
          <li>РП «Дообустройство месторождения Саватли скважины № 14, 15»;</li>\
          <li>РП «Обустройство скважин №13 Ойдин Шуртанского НГДУ».</li>\
          <li>РП «Модернизация установок предварительной подготовки газа УППГ-1,2 Шуртанского НГДУ»;</li>\
          <li>РП «Реконструкция установки стабилизации конденсата Шуртанского НГДУ»;</li>\
          <li>ТЭО проекта «Комплексное дообустройство месторождений Газлийского региона».</li>\
      </ul></br>\
      <p><strong>Текущие проекты (2022г.):</strong></p>\
      <ul>\
          <li>РП «Автоцистерналарга суюлтирилган газ юклаш шахобчаси» (Пункт налива сжиженных углеводородных газов (СУГ) в автоцистерны);</li>\
          <li>РП «Строительство газопровода от БВН-2 Зеварды до ДКС-2 Зеварды»;</li>\
          <li>ТЭО «Комплексного проекта «Поддержание добычи природного газа и газового конденсата по месторождениям АО «Узбекнефтегаз» на 2022-2026 годы»;</li>\
          <li>РД «Строительство объектов внешнего газоснабжения Шуртанского ГХК».</li>\
      </ul>",
    text_uz:
      "<p>\
          <strong>Каюмов Нозимбек Дилшод угли</strong> ведет свою деятельность в качестве Заместителя Главного инженера проекта АО «O‘ZLITINEFTGAZ» с мая 2021 года.\
      </p>\
      <p>За период работы под его руководством успешно разработаны следующие проекты:</p>\
      <ul>\
          <li>РП «Дообустройство месторождения Даяхатын скважины № 85, 86, 87, 88, 89, 90, 91»;</li>\
          <li>РП «Дообустройство месторождения Даяхатын скважины № 93, 94, 95»;</li>\
          <li>РП «Дообустройство месторождения Даяхатын скважины № 96, 97, 98, 99, 100, 101»;</li>\
          <li>РП «Дообустройство месторождения Саватли скважины № 11»;</li>\
          <li>РП «Дообустройство месторождения Саватли скважины № 14, 15»;</li>\
          <li>РП «Обустройство скважин №13 Ойдин Шуртанского НГДУ».</li>\
          <li>РП «Модернизация установок предварительной подготовки газа УППГ-1,2 Шуртанского НГДУ»;</li>\
          <li>РП «Реконструкция установки стабилизации конденсата Шуртанского НГДУ»;</li>\
          <li>ТЭО проекта «Комплексное дообустройство месторождений Газлийского региона».</li>\
      </ul></br>\
      <p><strong>Текущие проекты (2022г.):</strong></p>\
      <ul>\
          <li>РП «Автоцистерналарга суюлтирилган газ юклаш шахобчаси» (Пункт налива сжиженных углеводородных газов (СУГ) в автоцистерны);</li>\
          <li>РП «Строительство газопровода от БВН-2 Зеварды до ДКС-2 Зеварды»;</li>\
          <li>ТЭО «Комплексного проекта «Поддержание добычи природного газа и газового конденсата по месторождениям АО «Узбекнефтегаз» на 2022-2026 годы»;</li>\
          <li>РД «Строительство объектов внешнего газоснабжения Шуртанского ГХК».</li>\
      </ul>",
    text_oz:
      "<p>\
          <strong>Каюмов Нозимбек Дилшод угли</strong> ведет свою деятельность в качестве Заместителя Главного инженера проекта АО «O‘ZLITINEFTGAZ» с мая 2021 года.\
      </p>\
      <p>За период работы под его руководством успешно разработаны следующие проекты:</p>\
      <ul>\
          <li>РП «Дообустройство месторождения Даяхатын скважины № 85, 86, 87, 88, 89, 90, 91»;</li>\
          <li>РП «Дообустройство месторождения Даяхатын скважины № 93, 94, 95»;</li>\
          <li>РП «Дообустройство месторождения Даяхатын скважины № 96, 97, 98, 99, 100, 101»;</li>\
          <li>РП «Дообустройство месторождения Саватли скважины № 11»;</li>\
          <li>РП «Дообустройство месторождения Саватли скважины № 14, 15»;</li>\
          <li>РП «Обустройство скважин №13 Ойдин Шуртанского НГДУ».</li>\
          <li>РП «Модернизация установок предварительной подготовки газа УППГ-1,2 Шуртанского НГДУ»;</li>\
          <li>РП «Реконструкция установки стабилизации конденсата Шуртанского НГДУ»;</li>\
          <li>ТЭО проекта «Комплексное дообустройство месторождений Газлийского региона».</li>\
      </ul></br>\
      <p><strong>Текущие проекты (2022г.):</strong></p>\
      <ul>\
          <li>РП «Автоцистерналарга суюлтирилган газ юклаш шахобчаси» (Пункт налива сжиженных углеводородных газов (СУГ) в автоцистерны);</li>\
          <li>РП «Строительство газопровода от БВН-2 Зеварды до ДКС-2 Зеварды»;</li>\
          <li>ТЭО «Комплексного проекта «Поддержание добычи природного газа и газового конденсата по месторождениям АО «Узбекнефтегаз» на 2022-2026 годы»;</li>\
          <li>РД «Строительство объектов внешнего газоснабжения Шуртанского ГХК».</li>\
      </ul>",
    text_en:
      "<p>\
          <strong>Каюмов Нозимбек Дилшод угли</strong> ведет свою деятельность в качестве Заместителя Главного инженера проекта АО «O‘ZLITINEFTGAZ» с мая 2021 года.\
      </p>\
      <p>За период работы под его руководством успешно разработаны следующие проекты:</p>\
      <ul>\
          <li>РП «Дообустройство месторождения Даяхатын скважины № 85, 86, 87, 88, 89, 90, 91»;</li>\
          <li>РП «Дообустройство месторождения Даяхатын скважины № 93, 94, 95»;</li>\
          <li>РП «Дообустройство месторождения Даяхатын скважины № 96, 97, 98, 99, 100, 101»;</li>\
          <li>РП «Дообустройство месторождения Саватли скважины № 11»;</li>\
          <li>РП «Дообустройство месторождения Саватли скважины № 14, 15»;</li>\
          <li>РП «Обустройство скважин №13 Ойдин Шуртанского НГДУ».</li>\
          <li>РП «Модернизация установок предварительной подготовки газа УППГ-1,2 Шуртанского НГДУ»;</li>\
          <li>РП «Реконструкция установки стабилизации конденсата Шуртанского НГДУ»;</li>\
          <li>ТЭО проекта «Комплексное дообустройство месторождений Газлийского региона».</li>\
      </ul></br>\
      <p><strong>Текущие проекты (2022г.):</strong></p>\
      <ul>\
          <li>РП «Автоцистерналарга суюлтирилган газ юклаш шахобчаси» (Пункт налива сжиженных углеводородных газов (СУГ) в автоцистерны);</li>\
          <li>РП «Строительство газопровода от БВН-2 Зеварды до ДКС-2 Зеварды»;</li>\
          <li>ТЭО «Комплексного проекта «Поддержание добычи природного газа и газового конденсата по месторождениям АО «Узбекнефтегаз» на 2022-2026 годы»;</li>\
          <li>РД «Строительство объектов внешнего газоснабжения Шуртанского ГХК».</li>\
      </ul>",
    id: 6,
  },
  {
    image: "../assets/images/services/gipi-7.jpg",
    fullName_ru: "Рузиев Жасурбек Абдималикович",
    fullName_uz: "Рузиев Жасурбек Абдималикович",
    fullName_oz: "Ruziyev Jasurbek Abdimalikovich",
    fullName_en: "Ruziev Zhasurbek Abdimalikovich",
    position_ru: "Заместитель ГИПа",
    position_uz: "Заместитель ГИПа",
    position_oz: "Заместитель ГИПа",
    position_en: "Заместитель ГИПа",
    text_ru:
      "<p>\
          <strong>Рузиев Жасурбек Абдималикович</strong> ведет свою деятельность в качестве Заместителя Главного инженера проекта АО «O‘ZLITINEFTGAZ» с сентября 2023 года.\
      </p>\
      <p><strong>Текущие проекты (2023г.):</strong></p>\
      <ul>\
          <li>РП «Дообустройство месторождения Шуртан скважины № 360,361,362,363,364,365,366,367,368,369,370 Шуртанского НГДУ».\n</li>\
          <li>РП «Строительство устьевых компрессорных станций на месторождении Гирсан»</li>\
          <li>и другие предпроектные работы по объектам добычи, сбора, компримирования, транспортировки, подготовки и переработки углеводородов.</li>\
      </ul>",
    text_uz:
        "<p>\
            <strong>Рузиев Жасурбек Абдималикович</strong> ведет свою деятельность в качестве   Заместителя Главного инженера проекта АО «O‘ZLITINEFTGAZ» с сентября 2023 года.\
        </p>\
        <p><strong>Текущие проекты (2023г.):</strong></p>\
        <ul>\
            <li>РП «Дообустройство месторождения Шуртан скважины № 360,361,362,363,364,365,366,367,368,369,370 Шуртанского НГДУ».\n</li>\
            <li>РП «Строительство устьевых компрессорных станций на месторождении Гирсан»</li>\
            <li>и другие предпроектные работы по объектам добычи, сбора, компримирования, транспортировки, подготовки и переработки углеводородов.</li>\
        </ul>",
    text_oz:
        "<p>\
            <strong>Рузиев Жасурбек Абдималикович</strong> ведет свою деятельность в качестве Заместителя Главного инженера проекта АО «O‘ZLITINEFTGAZ» с сентября 2023 года.\
        </p>\
        <p><strong>Текущие проекты (2023г.):</strong></p>\
        <ul>\
            <li>РП «Дообустройство месторождения Шуртан скважины № 360,361,362,363,364,365,366,367,368,369,370 Шуртанского НГДУ».\n</li>\
            <li>РП «Строительство устьевых компрессорных станций на месторождении Гирсан»</li>\
            <li>и другие предпроектные работы по объектам добычи, сбора, компримирования, транспортировки, подготовки и переработки углеводородов.</li>\
        </ul>",
    text_en:
        "<p>\
            <strong>Рузиев Жасурбек Абдималикович</strong> ведет свою деятельность в качестве Заместителя Главного инженера проекта АО «O‘ZLITINEFTGAZ» с сентября 2023 года.\
        </p>\
        <p><strong>Текущие проекты (2023г.):</strong></p>\
        <ul>\
            <li>РП «Дообустройство месторождения Шуртан скважины № 360,361,362,363,364,365,366,367,368,369,370 Шуртанского НГДУ».\n</li>\
            <li>РП «Строительство устьевых компрессорных станций на месторождении Гирсан»</li>\
            <li>и другие предпроектные работы по объектам добычи, сбора, компримирования, транспортировки, подготовки и переработки углеводородов.</li>\
        </ul>",
    id: 7,
  },

];
